@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.passwordInputContainer {
	margin-top: 15px;
	display: flex;
	flex-wrap: nowrap;
	position: relative;

	input {
		width: 100%;
		padding: 15px;
	}

	.passwordTooltip {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		padding: 10px;
		background-color: #f7f7f7;
		border: 1px solid #ccc;
		border-radius: 5px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
		z-index: 5;
		text-align: left;
		color: green;
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			font-size: 14px;

			li {
				margin-bottom: 5px;
				&:before {
					content: "•";
					margin-right: 5px;
				}
			}
		}
	}
}

.inputWrapper {
	width: 100%;
	position: relative;

	& + & {
		margin-top: 15px;
	}
}

.inputWrapperError {
	border-bottom: 4px solid red !important;
}

.input {
	width: 100%;
	color: #E0E0E0;
	border-radius: 10px;
	padding: 15px;
	font-size: 16px;
	border: 1px solid transparent;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.inputDisabled {
	pointer-events: none;
}

.inputError {
	position: absolute;
	left: 15px;
	top: 2px;
	color: red;
	font-size: 12px;
	font-weight: 400;
}

.radius {
	border-radius: 10px 0 0 10px;
}

.containerEyeIcon {
	border-radius: 0 10px 10px 0;
	padding: 13px 13px 0 13px;
	border: none;

	.eyeIcon {
		width: 24px;
		height: 24px;
		cursor: pointer;
		fill: none;
		stroke: #E0E0E0;
	}
}

.containerEyeIconDark {
	background-color: $blackLighter;

	.eyeIcon {
		stroke: $white;
	}
}

.red {
	color: red !important;
	&:hover {
		color: red !important;
	}
}
