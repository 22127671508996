.statusRiskComp {
	width: 100%;
	position: relative;
}

.statusComp {
	width: 100% !important;
	height: 6.3px !important;
	display: flex !important;
	border-radius: 5px !important;
	border-radius: 20px !important;
}

.bigStatus {
	height: 10px;
}

.redStatus {
	background-color: #e02020;
	border-radius: 20px 0px 0px 20px;
}

.yellowStatus {
	background-color: #faca00;
}
.greenStatus {
	background-color: #6dd400;
	border-radius: 0px 20px 20px 0px;
}

.toulBarStatus {
	width: 47px;
	height: 35px;
	background-color: #ccc;
	position: absolute;
	top: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
}

.arrowDown {
	position: absolute;
	top: 90%;
	left: 53%;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
}

.redArrow {
	border-bottom: 10px solid #e02020;
	left: 8%;
}

.yellowArrow {
	border-bottom: 10px solid #faca00;
	left: 53%;
}

.greenArow {
	border-bottom: 10px solid #6dd400;
	left: 88%;
}

.toulBarStatusRed {
	background-color: #e02020;
	left: 0%;
	color: #fff !important;
}

.toulBarStatusGreen {
	background-color: #6dd400;
	left: 80%;
}

.toulBarStatusYellow {
	background-color: #faca00;
	left: 45%;
}

.circlStatus {
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	top: -2px;
}

.redCircl {
	background-color: #e02020;
}

.yellowCircl {
	background-color: #faca00;
}

.greenCircl {
	background-color: #6dd400;
}

.toulBarStatusSmall {
	display: flex !important;
	width: 37px;
	height: 25px;
	font-size: 10px !important;
	z-index: 3333;
	top: 14px;
}
