@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

$input-border-radius: 8px;
$input-padding: 12px 16px;
$error-color: #ff4d4f;
$focus-color: #1890ff;
$dark-input-bg: #3d3d3d;
$dark-text: #ffffff;
$dark-border: #404040;

.container {
	margin-top: 15px;
	display: flex;
	flex-wrap: nowrap;
}

.inputWrapper {
	width: 100%;
	position: relative;

	& + & {
		margin-top: 15px;
	}
}

.inputWrapperError {
	border-bottom: 2px solid $error-color !important;
}

.input {
	width: 100%;
	background-color: $dark-input-bg;
	color: $dark-text;
	border-radius: $input-border-radius;
	padding: $input-padding;
	font-size: 16px;
	border: 1px solid $dark-border;
	transition: all 0.2s ease-in-out;

	&::placeholder {
		color: rgba(255, 255, 255, 0.45);
	}

	&:hover {
		border-color: rgba(255, 255, 255, 0.3);
	}

	&:focus {
		outline: none;
	}

	&.error {
		border-color: $error-color;

		&:focus {
			box-shadow: 0 0 0 2px rgba($error-color, 0.2);
		}
	}

	// Disable autofill background
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: $dark-text;
		-webkit-box-shadow: 0 0 0px 1000px $dark-input-bg inset;
		transition: background-color 5000s ease-in-out 0s;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type="number"] {
		-moz-appearance: textfield;
	}
}

.inputDisabled {
	pointer-events: none;
}

.inputError {
	position: absolute;
	left: 15px;
	top: 2px;
	color: red;
	font-size: 12px;
	font-weight: 400;
}

.radius {
	border-radius: 10px 0 0 10px;
}

.containerEyeIcon {
	border-radius: 0 10px 10px 0;
	padding: 13px 13px 0 13px;
	border: none;

	.eyeIcon {
		width: 24px;
		height: 24px;
		cursor: pointer;
		fill: none;
		stroke: #E0E0E0;
	}
}

.containerEyeIconDark {
	background-color: $blackLighter;

	.eyeIcon {
		stroke: $white;
	}
}

.comment {
	background-color: transparent !important;
	font-size: 12px;
	font-weight: bold;
	width: 100% !important;
	resize: none;
}

.commentContainer {
	margin-top: 0px !important;
	width: 100% !important;
}

.noRedLine {
	margin-top: 0px !important;
}

.font12 {
	font-size: 12px;
	font-weight: bold;
	padding-left: 0px;
}

.paddingStyles {
	left: 15px;
}
