@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

* {
	font-family:
		Gotham Book,
		sans-serif;
}
.header {
	font-family:
		Gotham Book,
		sans-serif;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	height: auto;
	min-height: 150px;
	margin-bottom: 0;
	display: grid;
	& > :first-child {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}
}

.tabContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	font-size: 12px;
}

.tab {
	cursor: pointer;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
}

.tab:hover {
	color: #DB493C;
}

/* ControlsBody.module.scss */

.body {
	margin-top: 20px;
}

.controlItem {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 10px;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-bottom: 10px;
}

.controlItem span {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.DotsLoader {
	width: 100%;
	display: flex;
	justify-content: center;
}

.active {
	color: #DB493C;
}

.addBtn {
	background-color: #DB493C;
	color: #fff;
	width: 200px;
	height: 40px;
	border-radius: 30px;
	font-size: 12px;
	padding: 0px;
	letter-spacing: 0.3px;
	font-weight: bold;
	box-shadow: 0px 10px 30px #DB493C26;
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
}

.filterIcon {
	width: 15px;
	height: 15px;
	fill: #E0E0E0;
}

.filterContainer {
	display: flex;
	position: relative;
	gap: 4px;
	width: 100%;
	align-items: center;
	min-width: 120px;
}

.selectFilter {
	top: 28px;
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 4px;
	background-color: #1E1E1E;
	font-size: 12px;
	font-weight: bold;
	padding: 7px 8px;
	color: #242533;
	font-size: 10px;
	font-weight: bold;
	line-height: 16px;
	text-align: left;
	width: 100%;
	border-radius: 4px;
	z-index: 10;
	box-shadow: 0px 4px 4px 0px #00000014;
	max-height: 200px;
	overflow: auto;
	@include customScroll;
	.itemFilter {
		cursor: pointer;
		width: 100%;
		padding: 8px;
	}

	.activeFilter {
		color: #DB493C;
		background-color: rgba(51, 97, 255, 0.08);
		border-radius: 4px;
	}
}

.section1 {
	display: flex;
	gap: 5px;
	align-items: center;
}

.BtnFilters {
	width: 100%;
	min-width: 120px;
	display: flex;
	align-items: center;
	justify-content: space-between !important;
	color: #E0E0E0;
	background-color: #1E1E1E;
	font-size: 12px;
	font-weight: bold;
	padding: 7px 8px 7px 8px;
	gap: 8px;
	border-radius: 4px;
	height: 32px;
	padding: 7px 8px 7px 8px;
	border-radius: 4px;
}

.filterIconArrow {
	transform: rotate(90deg);
	width: 10px;
	height: 10px;
	fill: #E0E0E0;
}

.activeFilterList {
	border: 1px solid #DB493C;
	color: #DB493C;
	font-size: 12px;
}

.filterIconTrash {
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.BtnApply {
	border: 1px solid #DB493C;
	color: #DB493C;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #1E1E1E;
	font-weight: bold;
	padding: 7px 8px 7px 8px;
	border-radius: 4px;
	width: max-content;
}

.controlsSearch {
	input {
		font-size: 11px !important;
		&::placeholder {
			font-size: 11px !important;
		}
	}
}

.section3 {
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	display: flex;
	align-items: center;
	gap: 10px;
}

.bulkActionBtn {
	width: 112px;
	height: 32px;
	padding: 7px 8px 7px 8px;
	border-radius: 4px;
	background-color: #1E1E1E;
	font-size: 12px;
	font-weight: bold;
	color: #DB493C;
	border: 1px solid #DB493C;
	display: flex;
	justify-content: space-between;
	align-items: center;
	svg {
		fill: #DB493C;
		width: 15px;
		height: 15px;
	}
}

.filterTable {
	display: grid;
	gap: 8px;
}

.filterTableContents {
	display: grid;
	grid-template-columns: 120px 140px 15px;
	gap: 8px;
	align-items: center;
}

.itemFilter {
	display: flex;
	align-items: center;
	color: #E0E0E0;
	background-color: transparent;
	font-size: 12px;
	font-weight: bold;
	padding: 7px 8px 7px 8px;
	gap: 8px;
	border-radius: 4px;
	height: 32px;
	padding: 7px 8px 7px 8px;
	border-radius: 4px;
}

.applyFilter {
	display: flex;
	align-items: center;
	gap: 15px;
}

.filterText {
	display: flex;
	align-items: center;
	position: relative;
	width: 120px;
}

.filterTableContentsApply {
	display: flex;
	align-items: center;
	gap: 8px;
}

.headerContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.carouselContainer {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 15px;
}

.carousel {
	display: flex;
	overflow: hidden;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	gap: 15px;
}

.navButton {
	background-color: transparent;
	font-weight: bold;
	color: #E0E0E0;
	border: none;
	padding: 10px;
	cursor: pointer;
	font-size: 16px;

	&:disabled {
		visibility: hidden;
	}
}

.specialTab {
	width: 200px;
}
