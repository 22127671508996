.loaderContainer {
	width: 100%;
	height: max-content;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 14px;
	color: #E0E0E0;
}
