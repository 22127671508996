@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.container {
	display: flex;
	width: 100%;
	height: 100vh;
	overflow: auto;
}
.logo {
	width: 240px;
	height: 35px;
	margin: left;
	margin-left: 0px;
	margin-bottom: 20px;
	background-image: url("/assets/images/Logo_Text.png");
	background-size: contain;
	background-repeat: no-repeat;
	filter: brightness(0) invert(1);
}
footer {
	z-index: 5;
}

.form {
	width: 100%;
	height: 100%;
	text-align: center;
	padding-top: 50px;
	background-color: $secondary;
	overflow: auto;
	padding-bottom: 50px;
}

.formContainer {
	display: flex;
	font-size: 12px;
	font-weight: bold;
	width: 100%;
	height: 100%;
	justify-content: center;
}

.leftside {
	width: 50%;
	background-color: #DB493C;
	color: white;
	padding: 100px;
	display: flex;
	justify-content: right;
}

.leftside_content {
	height: 100%;
	width: 400px;
}

.left_header {
	font-size: 16px;
	color: #ffffff99;
}

.bottom_type_header {
	margin: 32px 0px;
}

.pricing {
	margin-bottom: 16px;
	display: flex;
	align-items: center;
}

.price {
	margin-right: 8px;
	font-size: 36px;
}

.per {
	color: #ffffff99;
	font-size: 14px;
}

.price_type {
	padding-bottom: 5px;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
}

.price_type_under {
	font-size: 12px;
	display: flex;
	justify-content: space-between;
	color: #ffffff99;
}

.price_type_sub {
	font-size: 12px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #ffffff1a;
	padding-bottom: 16px;
	padding-top: 25px;
}

.price_type_tax {
	font-size: 12px;
	display: flex;
	justify-content: space-between;
	color: #ffffff99;
	border-bottom: 1px solid #ffffff1a;
	margin-bottom: 16px;
	padding-bottom: 16px;
	padding-top: 16px;
}

.rightside {
	width: 50%;
	background-color: rgb(255, 255, 255);
	padding: 100px;
	display: flex;
	justify-content: left;
	color: #1a1a1ab3;
}

.rightside_content {
	height: 100%;
	width: 400px;
}
.right_header {
	padding-bottom: 20px;
	font-size: 20px;
}

.input_Size {
	justify-content: space-between;
	align-items: center;
	display: flex;
	padding: 15px 0px;
}
.inputer_plan {
	width: 100%;

	padding: 15px 0px;
	svg {
		fill: #E0E0E0;
	}
}
.inputer {
	width: 180px;
	svg {
		fill: #E0E0E0;
	}
}

.input_card {
	padding: 8px;
}

.cardMC {
	border-radius: 0px;
	display: flex;
	input {
		border-radius: 0px;
		border: 0px solid transparent;
	}
}

.cardnumber {
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.031372549);
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	opacity: 1;
	input {
		padding: 7px;
	}
}

.mm {
	margin: -2px 0px 0px 0px;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.031372549);
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	opacity: 1;
	input {
		padding: 7px;
	}
}

.cvc {
	margin: -2px 0px 0px -2px;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.031372549);
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	opacity: 1;
	input {
		border-radius: 0px 0px 16px 0px;
		padding: 7px;
	}
}

.formInput {
	margin-top: 10px;
	height: 34px;
	width: 100%;
	padding: 1px;
	box-sizing: border-box;
	border: none;
	border-radius: 13px;
	background-color: #e0e0e0;
	box-shadow:
		0 0 0 1px #e0e0e0,
		0 2px 4px 0 rgba(0, 0, 0, 0.07),
		0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
	input {
		padding: 6px;
	}
}

.cardname {
	padding: 8px 0px;
}

.submiter {
	margin-top: 25px;
}

.SignUpForm {
	box-shadow:
		0 0 0 0px #e0e0e0,
		0 2px 4px 0 rgba(0, 0, 0, 0.07),
		0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
	gap: 5px;
	margin-top: 10px;
	padding: 8px;
	border: 2px solid #e0e0e0;
	border-radius: 18px;
	display: flex;
	align-items: flex-start;
}

.checkboxForm {
	box-shadow:
		0 0 0 0px #e0e0e0,
		0 2px 4px 0 rgba(0, 0, 0, 0.07),
		0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
	border-radius: 2px;
	height: 16px;
	width: 20px;
}

.SignUpForm_text_content {
	color: #1a1a1a66;
}

.pyBtn {
	background-color: #DB493C;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ConfirmTerms {
	margin-top: 15px;
	line-height: 17px;
	text-align: center;
	color: #1a1a1a99;
}

footer {
	z-index: 5;
	text-align: center;
}

.Powered {
	margin-top: 50px;
	font-size: 14px;
	font-weight: bold;
}
