.container {
	width: 100%;
	height: max-content;
	display: flex;
	align-items: center;
	justify-content: center;
}
.loaderContainer {
	width: 100%;
	height: max-content;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px;
	font-weight: bold;
	font-size: 14px;
	color: #E0E0E0;
}
main {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	margin: 0 auto;
}

.pieContainer {
	position: relative;
	float: left;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
}

.pieContainer::after {
	content: "";
	position: absolute;
	top: 25%;
	left: 25%;
	z-index: 50;
	width: 50%;
	height: 50%;
	color: white;
	background-color: #f5f5f5;
	border-radius: 50%;
	box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.8);
}

.pieContainer .pie {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #aaaaaa;
	border-radius: 50%;
	clip: rect(0px, 75px, 150px, 0px);
}

.stats {
	float: left;
	margin-left: 50px;
}

.stats ul {
	list-style: none;
}

.stats li {
	position: relative;
	margin: 20px 0;
	font-weight: bold;
	color: #E0E0E0;
	display: flex;
	justify-content: space-between;
	width: 150px;
	font-size: 12px;
}

ul li:hover {
	color: #E0E0E0 !important;
}

.donutChart {
	margin-right: 15px;
}

.dataChart {
	margin-left: 15px;
}
