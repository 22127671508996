@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.main {
	display: flex;
	flex-direction: column;
	padding: 30px;
	gap: 30px;
}

.main > *:first-child {
	& > div {
		border: none !important;
	}
}
.faqQuestion {
	font-size: 15px;
	font-weight: bold;
	/* UI Properties */
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	cursor: pointer;
	padding: 30px 0px;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.faqResponse {
	/* UI Properties */
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	font-size: 15px;
	display: none;
	font-weight: bold;
}

.showResponse {
	opacity: 1;
	display: block;
	font-weight: 100;
}
