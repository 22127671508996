.fileTypeCard {
	border-radius: 5px;
	opacity: 1;
	width: 100px;
	height: 100px;
	padding: 5px;
	background-color: #ccc;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-weight: bold;
	cursor: pointer;
	margin: 0 auto;
}

.notFound {
	width: 80px;
}

.transparentStyle {
	background-color: transparent !important;
}

.imageOwner {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 5px;
	justify-content: center;
	text-align: center;
	width: 150px;
	justify-content: center;
	text-align: center;
}

.ownerImageComp {
	position: absolute;
	top: 50%;
	right: 38%;
}

.img {
	cursor: pointer;
	margin: 0 auto;
}

.imageName {
	font-size: 12px;
	font-weight: bold;
	color: #6b7a99;
	text-overflow: ellipsis;
	max-width: 100px;
	overflow: hidden;
	white-space: nowrap;
	max-width: 100%;
}

.imageFileName {
	margin-top: 18px;
}

.imageFileDate {
	font-size: 10px !important;
	color: #BDBDBD !important;
}

.fileIcon {
	display: flex;
	width: 16px;
	height: 16px;
	margin-right: 10px;
}

.imageNameContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.fixedImageFile {
	max-width: 130px;
	width: 130px;
}

.removeIcon {
	fill: #e02020;
}

.editIcon {
	fill: #6b7a99;
}

.addPopup {
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 30px #26334d08;
	border-radius: 10px;
	opacity: 1;
	overflow: hidden;
}

.controlCardPopup {
	border: 1px solid rgba(255, 255, 255, 0.2);
	background-color: #1E1E1E;
	margin: 0 auto;
	margin-top: 20px;
	border-radius: 10px;
}
.controlCardBodyPopup {
	background-color: #1E1E1E;
}

.closeIconPopup {
	width: 30px;
	height: 30px;
	padding: 8px;
	fill: #c3cad9;
	background-color: #1E1E1E;
	border-radius: 50%;
	cursor: pointer;
	box-shadow: 0px 10px 30px #DB493C26;
}

.controlPopupHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	padding: 0px 20px;
	height: 55px;
}

.BreakdownLeftHeader {
	display: flex;
	gap: 12px;
	align-items: center;
}

.controlPopupBody {
	display: flex;
	flex-direction: column;
	padding: 20px 30px;
	justify-content: space-evenly;
}

.fileBtn {
	width: fit-content !important;
	margin-left: 0px;
	height: 30px;
	display: flex;
	align-items: center;
	font-size: 12px;
	font-weight: bold;
}

.removeBtn {
	background-color: #e02020 !important;
	color: #fff !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.cancelBtn {
	background-color: #6b7a99 !important;
	color: #fff !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.loadingImg {
	position: relative;
	display: block;
	width: 100px;
	height: 100px;
	border-radius: 5px;
	background-color: hsl(220, 20%, 90%);
	animation: loading 0.8s infinite alternate;
}

@keyframes loading {
	to {
		background-color: hsl(220, 10%, 75%);
	}
}

.loadingImg::after {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1rem;
	background-color: hsl(220, 20%, 90%);
}

.removeWidthImageOwner {
	width: 0px;
}

.dropdownContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.dropdownItem {
	display: flex;
	padding: 5px;
	cursor: pointer;
}

.removeText {
	color: red;
}

.dots {
	width: 18px;
	height: 18px;
	cursor: pointer;
}
.markdownContainer {
	height: 100%;
	overflow: hidden;
}

.markdownContent {
	padding: 20px;
	height: 90%;
	overflow-y: auto;
	text-align: start;
}

.markdownContent h1,
.markdownContent h2 {
	color: #DB493C;
}

.markdownContent p {
	font-size: 16px;
	line-height: 1.6;
}

.markdownContent ul {
	list-style-type: square;
	padding-left: 20px;
}

.markdownContent blockquote {
	color: #6c757d;
	border-left: 5px solid #DB493C;
	padding-left: 15px;
	margin-left: 0;
	font-style: italic;
}

.markdownContent pre {
	background-color: #333;
	color: #fff;
	padding: 10px;
	border-radius: 5px;
	overflow-x: auto;
}
