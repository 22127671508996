@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.loader {
	border: 5px solid #f3f3f3;
	border-radius: 50%;
	border-top: 5px solid $blue;
	width: 20px;
	height: 20px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/**
 * ==============================================
 * Dot Windmill
 * ==============================================
 */
.dotWindmill {
	position: relative;
	top: -10px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
	transform-origin: 5px 15px;
	animation: dotWindmill 2s infinite linear;
}
.dotWindmill::before,
.dotWindmill::after {
	content: "";
	display: inline-block;
	position: absolute;
}
.dotWindmill::before {
	left: -8.66254px;
	top: 15px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
}
.dotWindmill::after {
	left: 8.66254px;
	top: 15px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
}
.whiteDots,
.whiteDots::after,
.whiteDots::before {
	background-color: #f4efef !important;
	color: #f4efef !important;
}
@keyframes dotWindmill {
	0% {
		transform: rotateZ(0deg) translate3d(0, 0, 0);
	}
	100% {
		transform: rotateZ(720deg) translate3d(0, 0, 0);
	}
}

.small {
	width: 10px;
	height: 10px;
}
