@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.chatMain {
	height: 100%;
	width: 100%;
}

.main {
	display: flex;
	padding: 30px;
	gap: 30px;
	height: 100%;
	padding-bottom: 30px;
}

.noReportMsg {
	display: flex;
	align-items: center;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
	justify-content: center;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	width: 100%;
}

.historiqueContainer {
	width: 350px;
	height: 100%;
	position: relative;
}
.historiqueChat {
	background: #1e1e1e 0% 0% no-repeat padding-box;
	border-radius: 10px;
	opacity: 1;
	padding: 15px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	// overflow: auto;
	cursor: pointer;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding-top: 87px;
	overflow-y: scroll;
	z-index: 0; /* Adjust z-index if necessary */
	overflow-x: hidden;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background: #f2f3f5;
	}

	&::-webkit-scrollbar-thumb {
		background: $blue;
	}
}
.messageTilesHistorique {
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 10px;
	opacity: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	height: 90px;
	position: relative;
}

.messageTiles {
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 13px;
	font-weight: bold;
	overflow: hidden;
}

.messageSenderDate {
	text-align: left;
	letter-spacing: 0px;
	color: #BDBDBD;
	font-size: 12px;
	font-weight: bold;
}

.removeIconHistorique {
	width: 15px;
	height: 15px;
	fill: #000;
}

.tilesSender {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 95%;
}
.planfeature {
	height: 100%;
	display: grid;
	justify-items: center;
	align-items: center;
	gap: 64px;
	align-content: center;
}
.planfeature_text {
	color: #E0E0E0;
	font-size: 24px;
	font-weight: bold;
}
.upgradeBtn {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	width: 145px !important;
	height: 40px !important;
	border-radius: 30px;
	box-shadow: 0px 10px 30px #8833ff1a;
	background-color: #DB493C !important;
	font-size: 12px !important;
	font-weight: bold !important;
}

.TilesHistorique {
	width: 100%;
	position: absolute;
	background: #1e1e1e 0% 0% no-repeat padding-box;
	z-index: 1;
	padding: 15px;
	padding-right: 20px;
}

.tilesSenderNewMessage {
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 10px;
	opacity: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	cursor: pointer;
}

.addNewChatIcon {
	width: 12px;
	height: 12px;
	fill: #E0E0E0;
}

.messageTilesHeader {
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 10px;
	opacity: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	display: flex;
	gap: 4px;
	align-items: center;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
}

.chatIcons {
	display: flex;
	gap: 8px;
}

.actionsContent {
	width: 350px;
	padding: 0 10px;
	border-radius: 20px;
	background-color: #1E1E1E;
	box-shadow: $shadow;
	position: absolute;
	top: 100%;
	z-index: 3;
	border: 1px solid #eaeaea;
	top: 50px;

	@media (max-width: 768px) {
		min-width: unset;
		width: 100%;
		position: static;
		border-radius: 0 0 7px 7px;
		margin-top: 10px;
	}
}

.dropResourceBtn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:hover {
		color: #DB493C;
		.resourcesIcon {
			fill: #DB493C;
		}
	}
}

.dropResourcActionsContent {
	border-radius: 8px;
	background-color: #fafbfc;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 300px !important;
	left: -18%;
	top: 80px;
}

.relative {
	position: relative;
}

.userItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dropHeader {
	font-size: 14px;
	width: 100%;
	display: flex;
	justify-content: center;
}

.messateText {
	/* UI Properties */
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 10px;
	opacity: 1;
	/* UI Properties */
	text-align: left;
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.btnSaveMessage {
	width: 100% !important;
	height: 50px !important;
	/* UI Properties */
	background: #DB493C 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 10px 30px #26334d0d !important;
	border-radius: 30px !important;
	opacity: 1;
	text-align: center !important;
	font-size: 13px;
	font-weight: bold;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.userList {
	padding: 0px 20px;
}

.overflow {
	overflow-x: initial;
	overflow-y: initial;
}

.activeChat {
	background-color: #3d3d3d;
}
