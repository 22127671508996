@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.main {
	height: 100%;
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 15px;
	margin-bottom: 0px;
}

.header {
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
	height: 80px;
	align-items: center;
	padding: 0px 20px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.headerDocument {
	height: 11%;
}

.rieghtHeaderDocument {
	display: contents;
	align-items: center;
}

.rieghtHeader {
	display: flex;
	align-items: center;
	width: 100%;
}

.headerFramework {
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	padding: 0px 50px;
	cursor: pointer;
	&:hover {
		color: #DB493C;
	}
}

.articleNumbers {
	cursor: pointer;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	padding: 0 4px;
	&:hover {
		color: #DB493C;
	}
}

.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted transparent;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	cursor: pointer;
	display: flex;
	padding: 0 4px;
}

.tooltip .tooltiptext {
	padding: 5px;
	visibility: hidden;
	width: 120px;
	background-color: white;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;

	/* Position the tooltip */
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 50%;
	margin-left: -60px;

	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	max-height: 100px;
	overflow-y: auto;
	@include customScroll;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}

.addBtn {
	background-color: #DB493C;
	width: 130px;
	height: 40px;
	border-radius: 30px;
	font-size: 12px;
	padding: 0px;
	letter-spacing: 0.3px;
	font-weight: bold;
	box-shadow: 0px 10px 30px #DB493C26;
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
}

.addControlBtn {
	color: #DB493C;
	border: 1px solid #DB493C;
	background-color: #1E1E1E;
	width: 130px;
	height: 40px;
	border-radius: 30px;
	font-size: 12px;
	padding: 0px;
	letter-spacing: 0.3px;
	font-weight: bold;
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
}

.subHeader {
	display: flex;

	justify-content: space-between;
	height: 80px;
	align-items: center;
	padding: 0px 20px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	height: 11%;
}

.body {
	height: 78%;
}

.controls {
	padding: 0px 20px;
	text-align: center;
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	font-size: 14px;
	font-weight: bold;
}
.gridSearch {
	display: flex;
	align-items: center;
	padding: 0px 20px;
	.grid {
		background-color: #1E1E1E;
		border-radius: 30px;
		padding: 12px;
		width: 100px;
		display: flex;
		justify-content: space-around;
		margin-right: 20px;
	}
}
.sort {
	padding: 0px 5px;
	/* UI Properties */
	text-align: left;
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	display: flex;
}

.sortItem {
	padding: 0px 10px;
	cursor: pointer;
}

.sortItemActive {
	color: #DB493C;
}

.svgGrid {
	width: 25px;
	height: 25px;
	cursor: pointer;
}

.displayAcitve {
	fill: #DB493C;
}

.search {
	width: 300px;
	height: 35px;
	& > input {
		font-size: 12px;
		&::placeholder {
			font-size: 12px;
		}
	}
}

.table {
	padding: 1em 0em;
	display: flex;
	flex-direction: column;
	height: 100%;

	.tableHeader {
		display: flex;
		height: 14%;
		padding: 0em 1em;
		div {
			letter-spacing: 0px;
			color: #E0E0E0;
			opacity: 1;
			font-size: 12px;
			font-weight: bold;
		}
	}

	.TableBody {
		height: 86%;
		overflow: auto;
		padding: 0em 1em;
		@include customScroll;
		display: grid;
		gap: 15px;
	}

	.row {
		display: flex;
		align-items: center;
		background: #1E1E1E 0% 0% no-repeat padding-box;
		box-shadow: 0px 2px 5px #26334d08;
		border-radius: 5px;
		opacity: 1;
		cursor: pointer;
		div {
			letter-spacing: 0px;
			color: #E0E0E0;
			opacity: 1;
			font-size: 12px;
			font-weight: bold;
		}
		& > *:first-child {
			border-right: 1px solid rgba(255, 255, 255, 0.2);
			padding: 20px 22px;
		}
	}
}

.imageProfil {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	color: #fff !important;
	font-size: 13px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	letter-spacing: 0.5px !important;
}

.name {
	min-width: 240px;
	text-align: center;
}

.description {
	width: 100%;
	padding: 15px;
	text-align: justify !important;
}

.risk {
	min-width: 100px;
	/* padding: 0px 62px; */
	text-align: -webkit-center;
}

.status {
	min-width: 70px;
	padding-right: 10px;
}

.statusComp {
	width: 100%;
	height: 6.3px;
	display: flex;
	border-radius: 5px;
	background-color: #999;
	border-radius: 20px;
}

.riskComp {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-shadow: 0px 2px 5px #26334d08;
	border: 1px solid rgba(255, 255, 255, 0.2);
	opacity: 1;
}

.moreSvg {
	width: 40px;
	height: 40px;
	padding: 10px;
	fill: #c3cad9;
	transform: rotate(90deg);
	cursor: pointer;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 30px;
	display: none;
}

.tableAction {
	min-width: 50px;
}

.bodyDataControls {
	display: flex;
	justify-content: center;
	padding: 50px;
	text-align: center;
	margin: auto;
}

.childRow {
	margin-left: 25px !important;
}

.activeFramework {
	color: #DB493C !important;
}

.statusCompDiv {
	width: 100%;
	height: 2px;
	background-color: #DB493C;
}

.childName {
	min-width: 215px;
}

.owner {
	min-width: 100px;
}

.nameHeader {
	padding: 15px;
	text-align: left !important;
	padding-left: 40px !important;
}

.ownerHeader {
	padding: 15px;
}

.riskheader {
	padding-top: 15px;
}

.statusHeader {
	padding-top: 15px;
}

.closeIcon {
	width: 15px;
	height: 15px;
	fill: #c3cad9;
	cursor: pointer;
	&:hover {
		fill: #DB493C;
	}
}

.addBtnMembers {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 110px;
	height: 30px;
	padding: 0px;
	letter-spacing: 0.3px;
	font-weight: bold;
	background: #DB493C 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
	font-size: 10px;
	svg {
		width: 10px;
		height: 10px;
		transform: rotate(90deg);
		fill: #c3cad9;
	}
}

.formBody {
	display: flex;
}

.leftformBody {
	width: 50%;
	height: 2px;
	opacity: 1;
	height: 100%;
}

.riegthformBody {
	width: 50%;
	height: 100%;
}

.controlCard {
	margin-top: 30px;
	margin-left: 30px;
	width: 80%;
}

.controlCardLabel {
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.breakDown {
	width: 100px !important;
}

.controlCardValue {
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 12px;
	padding-top: 20px;
	font-weight: bold;
	text-align: justify;
	padding-left: 5px;
}

.cardSpace {
	padding-top: 48px;
}

.saveBtn {
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 65px;
	height: 40px;
	padding: 0px;
	letter-spacing: 0.3px;
	font-weight: bold;
	background: #DB493C 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
	font-size: 10px;
}

.evidenceAction {
	display: flex;
	margin-top: auto;
	align-items: center;
	& > :first-child {
		margin-left: auto;
	}
}

.evidence {
	background: #1E1E1E;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
	padding: 10px;
	min-height: 170px;
	display: flex;
	flex-direction: column;
}

// .commentInput{

// }

.downloadBtn {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 115px;
	height: 30px;
	padding: 0px;
	letter-spacing: 0.3px;
	font-weight: bold;
	/* UI Properties */
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d0d;
	border-radius: 5px;
	opacity: 1;
	font-size: 10px;
	color: #6b7a99;
	margin-left: 10px;
	display: flex;
	align-items: center;
}

.attachement {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 30px;
	padding-top: 45px;
}

.attachementTitle {
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 14px;
	font-weight: bold;
}

.controlCardattachement {
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	margin-top: 0px;
	margin-top: 25px;
	width: 90%;
}

.attachementBtns {
	display: flex;
}

.attachementIcon {
	width: 40px;
	height: 40px;
	fill: #c3cad9;
	padding: 5px;
	margin: 0px 5px;
	cursor: pointer;
	&:hover {
		fill: #DB493C;
	}
}

.gridIconPadding {
	padding: 7px !important;
}

.downloadIcon {
	width: 20px;
	height: 20px;
	fill: #c3cad9;
}

.evidenceIcon {
	width: 20px;
	height: 20px;
	fill: #c3cad9;
	margin: 0px 8px;
	cursor: pointer;
	&:hover {
		fill: #DB493C;
	}
}

.transfomIcon {
	transform: rotate(90deg);
}

.formrisk {
	width: 100%;
	display: flex;
	padding: 30px;
	border-left: 1px solid rgba(255, 255, 255, 0.1);
	align-items: center;
}

.dropDownRisk {
	display: flex;
	width: 50%;
	flex-direction: column;
}

.formstatus {
	display: flex;
	width: 50%;
	flex-direction: column;
}

.selectRisk {
	width: 80% !important;
	justify-content: space-between !important;

	svg {
		fill: #dadee6 !important;
	}
	& > div {
		width: 100% !important;
		justify-content: space-between;
		background: #1E1E1E 0% 0% no-repeat padding-box !important;
		box-shadow: 0px 2px 5px #26334d0d;
		border: 2px solid #f5f6f7 !important;
		border-radius: 5px !important;
		opacity: 1;
	}
}

.riskTitle {
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.submissionDate {
	width: 100%;
	display: flex;
	padding: 20px;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.dateRisk {
	width: 50%;
	justify-content: center;
	display: flex;
	align-items: center;
	border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.dateRiskText {
	width: 50%;
	justify-content: center;
	display: flex;
	align-items: center;
}

.datRiskBtn {
	background: #DB493C 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
	display: flex;
	color: #fff;
	font-size: 10px;
	font-weight: bold;
	align-items: center;
	justify-content: space-between;
	width: 150px;
	height: 30px;
}

.taskList {
	padding: 30px;
	border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.taskListTitle {
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 14px;
	font-weight: bold;
	font-size: 12px;
}

.listComments {
	padding: 30px;
}

.listComments {
	padding: 30px;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	max-height: 300px;
	border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.listCommentsTitle {
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 14px;
	font-weight: bold;
}

.submitForm {
	padding: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	margin-top: auto;
	border-left: 1px solid rgba(255, 255, 255, 0.1);
	& > div {
		width: 100%;
		height: 100%;
	}
}

.submitFormTitle {
	text-align: left;
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.submitBtn {
	background: #DB493C 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
	width: 150px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.iconTo {
	width: 12px;
	height: 12px;
	fill: #c3cad9;
	margin: 0px 5px;
}

.paddingTo {
	padding: 0px !important;
}

.listOfFrameworks {
	padding: 50px;
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	overflow: auto;
}

.framworkItem {
	display: flex;
	padding: 5px;
	justify-content: space-between;
	font-size: 12px;
	font-weight: bold;
	align-items: center;
}

.addFrameworkBtn {
	min-width: 100px;
	max-width: 100px;
	height: 30px;
	display: flex;
	align-items: center;
	font-size: 10px;
	font-weight: bold;
	background-color: #DB493C;
	color: #fff;
	justify-content: space-around;
}

.searchAddFramework {
	width: 100%;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px rgb(38 51 77 / 14%);
	border-radius: 30px;
	opacity: 1;
}

.searchFramwork {
	padding: 15px 0px;
}

.loaderAdd {
	width: 20px;
	height: 20px;
	fill: #fff;
}

.ownerList {
	display: flex;
	align-items: center;
}

.ownerName {
	margin-right: 10px;
}

.svgAdd {
	width: 30px;
	height: 30px;
	background-color: #1E1E1E;
	fill: #E0E0E0;
	cursor: pointer;
	padding: 6px;
	border-radius: 50%;
	box-shadow: 0px 10px 30px #DB493C26;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		fill: #DB493C;
	}
}

.headerFrameworkUser {
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	padding: 0px 15px;
}

.btnDanger {
	background-color: #e02020;
}

.btnPopupText {
	font-size: 12px;
	font-weight: bold;
	text-align: center;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
}

.popupInconOwner {
	cursor: pointer;
}

.popupTopStyles {
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 18px !important;
	font-weight: bold;
	display: flex;
	padding: 20px 15px !important;
	align-items: center;
	border-bottom: 2px solid #edeff2 !important;
}

.inputComment {
	background-color: #f7f9fb !important;
	font-size: 12px;
	font-weight: bold;
	background-color: #f7f9fb;
}

.img {
	border-radius: 5px;
	opacity: 1;
	width: 100px;
	height: 100px;
}

.gridImgs {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fill, 144px);
	margin-left: 3px;
	margin-top: 20px;
	justify-content: space-between;
}

.topPopupAddFramwork {
	padding: 40px 90px;
	text-align: center;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	background: #1E1E1E 0% 0% no-repeat padding-box;
	opacity: 1;
	border-radius: 20px 20px 0px 0px;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.addframworkTitle {
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
}

.childControl {
	padding-left: 25px;
}

.addframworkTitleCenter {
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	text-align: center;
}

.gridRow {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(228px, 228px));
	grid-gap: 15px 15px;
	justify-content: center;
	align-items: center;
	overflow: auto;
	padding-top: 35px;
	@include customScroll;
}

.gridRow .gridItem:hover {
	filter: contrast(0.7);
}
.gridRow .gridItem {
	border: 1px solid rgba(255, 255, 255, 0.1);
	transition: filter 0.5s ease;
	display: flex;
	cursor: pointer;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	text-align: center;
	flex-direction: column;
	align-items: stretch;
	max-width: 245px;
	min-height: 200px;
	border-radius: 5px;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	position: relative;
}

.gridItem {
	padding: 25px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.addPopup {
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 30px #26334d08;
	border-radius: 10px;
	opacity: 1;
	height: 100%;
	overflow: hidden;
}

.addPopupFramwork {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.selectedFramwork {
	border: 2px solid #DB493C;
	border-radius: 5px;
}
.disabledSelected {
	border: 2px solid #DB493C;
	border-radius: 5px;
	pointer-events: none;
}
.centerFrameworkName {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
}

.listBtnsAddframework {
	display: flex;
	padding: 25px 50px;
	justify-content: right;
	.addBtn {
		margin-left: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		.svg {
			width: 10px;
			height: 10px;
		}
	}
}

.backroundFrameworkCancel {
	background-color: #c3cad9;
}

.comment {
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d0d;
	border-radius: 5px;
	opacity: 1;
	width: 100%;
	max-height: 200px;
	height: max-content;
	font-size: 12px;
	font-weight: bold;
	overflow: auto;
	padding: 16px;
}

.commentContainer {
	display: flex;
	gap: 20px;
	margin: 15px 0px;
}
.profileimage {
	width: 40px !important;
}

.statusRisk {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	position: relative;
}

.bigStatus {
	height: 10px;
}

.commentsHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 14px;
}

.spanCommented {
	text-align: left;
	letter-spacing: 0px;
	color: #BDBDBD;
	opacity: 1;
	font-size: 12px;
	margin-left: 10px;
}

.calendarIcon {
	width: 20px;
	height: 20px;
	fill: #fff;
}
.listStakeholdersImage {
	display: flex;
	align-items: center;
	gap: 5px;
}

.redStatus {
	background-color: #e02020;
	border-radius: 20px 0px 0px 20px;
}

.yellowStatus {
	background-color: #faca00;
}
.greenStatus {
	background-color: #6dd400;
	border-radius: 0px 20px 20px 0px;
}

.toulBarStatus {
	width: 47px;
	height: 35px;
	background-color: #ccc;
	position: absolute;
	top: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
}
.arrowDown {
	position: absolute;
	top: 90%;
	left: 53%;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
}

.statusRiskComp {
	width: 100%;
	position: relative;
}

.upload input[type="file"] {
	appearance: initial;
	opacity: 0;
	width: 350px;
	height: 100px;
	cursor: pointer;
	position: absolute;
}

.upload .choosen span {
	display: block;
}

.formUp {
	width: 100%;
	height: 110px;
}

.formUp p {
	text-align: center;
	margin: 5px;
}

.uploadIcon {
	width: 45px;
	height: 31px;
	fill: #c3cad9;
}

.btnPlusLoader {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	width: 80px;
}

.btnPlusLoaderBrowse {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	width: 80px;
	margin: auto;
}

.choosen {
	letter-spacing: 0px;
	color: #BDBDBD;
	opacity: 1;
	font-size: 12px;
	list-style: circle;
	margin: 0px 20px;
}

.redArrow {
	border-bottom: 10px solid #e02020;
	left: 8%;
}

.yellowArrow {
	border-bottom: 10px solid #faca00;
	left: 53%;
}

.greenArow {
	border-bottom: 10px solid #6dd400;
	left: 88%;
}

.toulBarStatusRed {
	background-color: #e02020;
	left: 0%;
}

.toulBarStatusGreen {
	background-color: #6dd400;
	left: 80%;
}

.toulBarStatusYellow {
	background-color: #faca00;
	left: 45%;
}

.controlCardHeaderIconPopup {
	height: 25px;
	width: 25px;
}
.controlCardHeaderLeftPopup {
	display: flex;
	align-items: center;
	gap: 20px;
}

.controlCardHeaderPopup {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* UI Properties */
	background: #1E1E1E 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
	opacity: 1;
	padding: 10px 20px;
	height: 65px;
	width: 100%;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	border: 1px solid rgba(255, 255, 255, 0.2);
}

.controlCardPopup {
	width: 80%;
	border: 1px solid rgba(255, 255, 255, 0.2);
	background-color: #1E1E1E;
	margin: 0 auto;
	margin-top: 20px;
	border-radius: 10px;
}
.controlCardBodyPopup {
	background-color: #1E1E1E;
}

.closeIconPopup {
	width: 30px;
	height: 30px;
	padding: 8px;
	fill: #c3cad9;
	background-color: #1E1E1E;
	border-radius: 50%;
	cursor: pointer;
	box-shadow: 0px 10px 30px #DB493C26;
}

.controlPopupHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
	opacity: 1;
	height: 9%;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	padding: 0px 20px;
}

.controlCardBodyPopup {
	margin: 30px;
	max-height: 100px;
	height: 100px;
	overflow: auto;
	@include customScroll;
	border-left: 3px solid;
	display: flex;
	align-items: center;
}

.controlCardBodyPopupBrekdown {
	margin: 30px;
	max-height: 100px;
	height: 100px;
	overflow: auto;
	@include customScroll;
	border-left: 3px solid;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
}

.borderRed {
	border-left: 3px solid red;
}

.borderBlue {
	border-left: 3px solid blue;
}

.borderGreen {
	border-left: 3px solid green;
}

.controlCardBodyPopup {
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
}

.textPopup {
	padding: 10px;
	& > * {
		padding: 0px;
		ul {
			list-style: circle;
			padding: 20px;
			padding-top: 0px;
			li {
				margin-bottom: 10px;
			}
		}
	}
}

.controlPopupBody {
	height: 90%;
	@include customScroll;
}

.arrowInverst {
	width: 30px;
	height: 30px;
	padding: 9px;
	fill: #c3cad9;
	background-color: #1E1E1E;
	border-radius: 4px;
	cursor: pointer;
	border: 1px solid rgba(255, 255, 255, 0.2);
	transform: rotate(90deg);
}

.addscroll {
	overflow: auto;
	@include customScroll;
}

.ReportDate {
	display: flex;
	width: 80%;
	justify-content: space-evenly;
	margin: 21px 5px;
	align-items: center;
}

.startEndDate {
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	text-align: center;
}

.startEndTime {
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	margin: 5px 0px;
}

.arrowDateStyle {
	width: 15px;
	height: 15px;
	fill: #dadee6;
}

.taskContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	margin: 12px 0px;
	.taskOwner {
		color: #DB493C;
		margin-right: 10px;
	}
}

.activitiesContainer {
	overflow: auto;
	@include customScroll;
	max-height: 200px;
	overflow: auto;
	padding: 5px;
	margin: 10px 0px;
	display: flex;
	flex-direction: column;
}

.commentContainerDiv {
	display: flex;
	flex-direction: column;
	overflow: auto;
	max-height: 200px;
	padding: 5px;
	@include customScroll;
	margin-top: 5px;
}

.borderContainer {
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 15px;
}

.removeBorder {
	border: 0px;
}

.circlStatus {
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	top: -2px;
}

.redCircl {
	background-color: #e02020;
}

.yellowCircl {
	background-color: #faca00;
}

.greenCircl {
	background-color: #6dd400;
}

.toulBarStatusSmall {
	width: 37px;
	height: 25px;
	font-size: 10px !important;
	z-index: 3333;
	top: 14px;
}

.filterBtn {
	background-color: #DB493C;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	background: #DB493C 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
	width: 85px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-left: 15px;
	height: 38px;
	padding: 0px;
	letter-spacing: 0.7px;
}

.searchFramworkPopup {
	display: flex;
	justify-content: space-evenly;
	height: 40px;
	align-items: center;
}

.filterIcon {
	width: 15px;
	height: 15px;
	fill: #f5f6f7;
}

.actionsContent {
	min-width: 330px;
	position: absolute;
	top: 100%;
	left: 15px;
	z-index: 3;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 60px 120px #26334d0d;
	border-radius: 10px;
	opacity: 1;
}

.searchContainerFramwork {
	position: relative;
}

.AddFrameworkPopupHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	font-weight: bold;
	color: #6b7a99;
	padding: 20px 25px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	svg {
		fill: #6b7a99;
		width: 15px;
		height: 15px;
		cursor: pointer;
	}
}

.AddFrameworkPopupBody {
	font-size: 12px;
	color: #6b7a99;
	font-weight: bold;
	width: 100%;
	text-align: left;
	padding: 20px 25px;
}

.selectClassName {
	width: 100%;
	height: 50px;
	margin: 15px 0px;
	font-size: 12px;
	font-weight: bold;
	&,
	svg {
		fill: #dadee6 !important;
	}
}
.selectClassName > *:first-child {
	height: 100%;
}
.AddFrameworkPopupFooterBtn {
	width: 120px;
	height: 50px;
	background: #DB493C 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 30px;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.AddFrameworkPopupFooterBtnReset {
	display: flex;
	align-items: center;
	cursor: pointer;
	svg {
		width: 20px;
		height: 20px;
		fill: #dadee6;
	}
}

.AddFrameworkPopupFooter {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.frameworkCategory {
	position: absolute;
	bottom: 5%;
	left: 5%;
	background: #17a5e6 0% 0% no-repeat padding-box;
	border-radius: 30px;
	opacity: 1;
	padding: 5px;
	font-size: 10px;
	font-weight: bold;
	color: #fff;
	width: 50px;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.NoFramworkFound {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: bold;
	color: #E0E0E0;
}

.eventsListSubmission {
	max-height: 110px;
	height: 110px;
	overflow: auto;
	@include customScroll;
	padding: 0px 10px;
	width: 50%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.eventItem {
	margin: 3px 0px;
	width: 100%;
	height: 30px;
	min-height: 30px;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d0d;
	border-radius: 2px;
	opacity: 1;
	font-size: 10px;
	font-weight: bold;
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding-left: 5px;
}

.eventsLoader {
	margin: 3px 0px;
	width: 100%;
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: center;
}

.eventsListSubmissionLoader {
	overflow: hidden;
}

.dragDropText {
	font-size: 12px;
	font-weight: bold;
	color: #6b7a99;
}

.styleListing {
	display: flex;
	list-style: none;
	width: 305px;
	margin: auto;
	height: 50px;
	box-shadow: 0px 10px 30px #26334d08;
	border-radius: 30px;
	opacity: 1;
	align-items: center;
}

.filesClose {
	width: 40px;
	height: 40px;
	padding: 10px;
	fill: #c3cad9;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 15px #26334d0d;
	border-radius: 50%;
	margin-right: 15px;
	cursor: pointer;
}

.filesDragAndDropArea {
	width: 100%;
	height: 150px;
	padding: 0px;
	display: flex;
	flex-flow: column nowrap;
	text-align: center;
	font-size: 12px;
	font-weight: bold;
	color: #555555;
	border-radius: 12px;
	overflow: auto;
	@include customScroll;
	.areaIcon {
		font-size: 64px;
		margin-top: 20px;
	}
}

.upload input[type="file"] {
	appearance: initial;
	opacity: 0;
	width: 89px;
	cursor: pointer;
	position: absolute;
	height: 100%;
}

.formUp {
	width: 100%;
	height: 35px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 15px;
}

.formUp div {
	text-align: center;
}

.customUploadBtn {
	height: 31px;
	width: 89px;
	background-color: #DB493C;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	display: flex;
	align-items: center;
	border-radius: 5px;
	justify-content: center;
	text-align: center;
}

.documentFormUoImage {
	width: 100%;
	height: 150px;
	padding: 0px;
	display: flex;
	flex-flow: column nowrap;
	text-align: center;
	font-size: 12px;
	font-weight: bold;
	color: #555555;
	border-radius: 12px;
	justify-content: center;
	align-items: center;
}

.blueSend {
	fill: #DB493C;
}

.topborderActivites {
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.newFramework {
	background-color: #DB493C;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	width: 143px;
	height: 38px;
	padding: 0px;
}

.newFrameworkDescrption {
	border-right: 1px solid rgba(255, 255, 255, 0.2);
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.newFrameworkNameAssign {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.newFrameworkName {
	min-width: 270px;
	max-width: 270px;
}

.newFrameworkRow {
	padding: 10px 0px;
}

.newFrameworkAction {
	min-width: 100px;
	max-width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.CustomSvgIcons {
	display: block;
	margin: auto;
	width: 25px;
	height: 25px;
	// margin-right: 10px;
	fill: #ffffff;
	border-radius: 50%;
	background-color: #c3cad9;
	padding: 5px;
}

.svgIconsEmpty {
	display: block;
	margin: auto;
	width: 25px;
	height: 25px;
	//margin-right: 10px;
	fill: #ffffff;
	border-radius: 50%;
	cursor: pointer;
	padding: 5px;
	border: #c3cad9 2px solid;
}

.titleCustomFramework {
	font-size: 14px;
	font-weight: bold;
	text-align: left;
	letter-spacing: 0px;
	color: #DB493C;
	opacity: 1;
	border-right: 1px solid rgba(255, 255, 255, 0.1);
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 15px;
}

.titleCustomFrameworkInput {
	background-color: transparent;
	margin: 0px;
	font-size: 14px;
	font-weight: bold;
	color: #E0E0E0;
	margin-left: 15px;
	width: 300px;
	height: 100%;
}

.rieghtHeaderHiegth {
	height: 100%;
}

.addPopup {
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 30px #26334d08;
	border-radius: 10px;
	opacity: 1;
	overflow: hidden;
}

.controlCardPopup {
	border: 1px solid rgba(255, 255, 255, 0.2);
	background-color: #1E1E1E;
	margin: 0 auto;
	margin-top: 20px;
	border-radius: 10px;
}
.controlCardBodyPopup {
	background-color: #1E1E1E;
}

.closeIconPopup {
	width: 30px;
	height: 30px;
	padding: 8px;
	fill: #c3cad9;
	background-color: #1E1E1E;
	border-radius: 50%;
	cursor: pointer;
	box-shadow: 0px 10px 30px #DB493C26;
}

.controlPopupHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	padding: 0px 20px;
	height: 55px;
}

.controlPopupBody {
	display: flex;
	padding: 20px 30px;
	justify-content: space-evenly;
}

.controlPopupBodyGod {
	display: flex;
	height: 90%;
	@include customScroll;
}

.fileBtn {
	width: fit-content !important;
	margin-left: 0px;
	height: 30px;
	display: flex;
	align-items: center;
	font-size: 12px;
	font-weight: bold;
}

.removeBtn {
	background-color: #e02020 !important;
	color: #fff !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.cancelBtn {
	background-color: #6b7a99 !important;
	color: #fff !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.bodyframwo {
	padding: 20px;
	padding-bottom: 0px;
}

.bodyFameworkRemove {
	width: 100%;
	display: list-item;
	list-style: circle;
	margin: 0px 25px;
	font-size: 12px;
	font-weight: bold;
}
.AllcontrolsCarousel {
	padding: 0px 40px;
}

.flexderec {
	flex-direction: column !important;
}

.individualPlan {
	height: 100%;
	grid-auto-rows: max-content;
}

.BreakdownLeftHeader {
	display: flex;
	gap: 12px;
	align-items: center;
}

.leftHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
}

.BreakdownBtn {
	width: 150px !important;
	height: 35px !important;
	background-color: #DB493C !important;
	color: #fff !important;
	border-radius: 24px !important;
	font-size: 12px !important;
	font-weight: bold !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btnStatus {
	width: 80px !important;
	height: 25px !important;
	background-color: #DB493C !important;
	color: #fff !important;
	border-radius: 24px !important;
	font-size: 12px !important;
	font-weight: bold !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.actionsContentStatus {
	padding: 0 10px;
	border-radius: 20px;
	background-color: #1E1E1E;
	box-shadow: $shadow;
	position: absolute;
	top: 100%;
	z-index: 3;
	border: 1px solid #eaeaea;
	min-width: 200px;
	@media (max-width: 768px) {
		min-width: unset;
		width: 100%;
		position: static;
		border-radius: 0 0 7px 7px;
		margin-top: 10px;
	}
}

.actionsContentBtn {
	display: flex;
	justify-content: center;
	padding: 15px 5px;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	cursor: pointer;
	transition: all 0.15s;
	align-items: center;
}

.dropResourceBtn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 5px;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	cursor: pointer;
	width: 200px;
	&:hover {
		color: #DB493C;
		.resourcesIcon {
			fill: #DB493C;
		}
	}
}

.dropResourcActionsContent {
	border-radius: 8px;
}

.divStatus {
	position: relative;
	margin-left: 15px;
}

.dotsStyles {
	border-radius: 50%;
}

.resourcesIcon {
	width: 35px;
	height: 35px;
	fill: #E0E0E0;
	padding: 10px;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	// border-radius: 50%;
}
.relativeDots {
	position: relative;
}

.statusPosition {
	right: 0% !important;
}

.activeControle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.colorFunction {
	background-color: #33bfff !important;
}

.NoResourcesFound {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	grid-column: 1 / span 4;
}

.resourcesRow {
	width: auto;
	padding: 0px 8px;
	cursor: pointer;
}

.addPadding {
	padding: 8px;
	width: max-content;
}

.disable {
	pointer-events: none;
}

.folderStyles {
	padding: 8px;
	width: 70px !important;
	height: 70px !important;
}

.bodyTypeContainer {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.extention {
	display: flex;
	width: 50px;
	height: 60px;
	border: 2px solid #DB493C;
	border-radius: 5px;
	opacity: 1;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: bold;
	color: #DB493C;
}

.repositoryPathDiv {
	display: flex;
	align-items: center;
	padding: 0px 40px;
}

.repositoryPath {
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	cursor: pointer;
	&:hover {
		color: #DB493C;
	}
}

.loaderResources {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.controlsPending {
	width: 100%;
	height: 100%;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.controlsPending {
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
}

.hFull {
	height: 100%;
	width: 100%;
}

.dropDownControl {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	box-shadow: 0px 2px 5px #26334d08;
}

.dropdownHeader {
	width: 100%;
	font-weight: bold;
	font-size: 13px;
	text-align: center;
	letter-spacing: 0px;
	color: #DB493C;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
	margin-bottom: 10px;
}

.dropdownBody {
	width: 100%;
	text-align: center;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 12px;
	// border: 1px solid #ccc;
	height: 20px;
	padding: 5px 10px;
	& > svg {
		width: 10px;
		height: 10px;
		transform: rotate(90deg);
		fill: #E0E0E0;
	}
}

.addAdssignUserIcon {
	width: 15px;
	height: 15px;
	fill: #DB493C;
	transform: rotate(0deg) !important;
}

.addAdssignListUser {
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 12px;
	font-weight: bold;
	color: #DB493C;
	cursor: pointer;
	display: flex;
	gap: 10px;
	align-items: center;
}

.ownerStyles {
	svg {
		width: 25px;
		height: 25px;
		fill: #c3cad9;
		background-color: #f7f8fa;
		transform: rotate(90deg);
		padding: 5px;
		border-radius: 50%;
	}
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.listUsersTilte {
	position: absolute;
	left: 30%;
	font-size: 14px;
	font-weight: bold;
	color: #6b7a99;
}

.listUserName {
	font-size: 12px;
	font-weight: bold;
	color: #6b7a99;
	text-transform: capitalize;
	text-align: justify;
}

.listUserNameRole {
	font-size: 12px;
	font-weight: bold;
	color: #BDBDBD;
}

.dropdownBodyList {
	width: 100%;
}

.repositoryBtn {
	width: 150px;
	height: 30px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #DB493C;
	color: #edeff2;
	border-radius: 4px;
	font-size: 12px;
	font-weight: bold;
}

.repoBtns {
	display: flex;
	align-items: center;
	justify-content: right;
	gap: 8px;
}

.repoMessage {
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	text-align: justify;
}

.spanRepoMessage {
	color: #E0E0E0;
}

.divRepository {
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 8px;
}

input[type="checkbox"],
input[type="radio"] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 2px solid #ccc;
	outline: none;
	transition: all 0.3s ease;
	margin-right: 10px;
	box-shadow: inset 0 0 0 3px #fff;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
	background-color: #DB493C;
	border-color: #DB493C;
	box-shadow: inset 0 0 0 3px #fff !important;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
	box-shadow: 0 0 3px #DB493C;
}

.saveBtnAssign {
	width: 100%;
	height: 30px;
	background: #DB493C 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 30px #26334d0d;
	border-radius: 30px;
	text-align: center;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.listUserNameRoleAttach {
	font-size: 10px;
	font-weight: bold;
	color: #BDBDBD;
	margin-left: 5px;
}

.auditHeaderTitle {
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	padding: 0px 50px;
	cursor: pointer;
	color: #DB493C;
	width: 100%;
	text-align: center;
}

.tableAuditor {
	margin: 1em;
	display: grid;
	gap: 12px;

	.tableHeaderAuditor {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: 280px auto 100px;
		div {
			letter-spacing: 0px;
			color: #E0E0E0;
			opacity: 1;
			font-size: 12px;
			font-weight: bold;
		}
	}

	.rowAuditor {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: 280px auto 100px;
		align-items: center;
		background: #1E1E1E 0% 0% no-repeat padding-box;
		box-shadow: 0px 2px 5px #26334d08;
		border-radius: 5px;
		opacity: 1;
		cursor: pointer;
		div {
			letter-spacing: 0px;
			color: #E0E0E0;
			opacity: 1;
			font-size: 12px;
			font-weight: bold;
		}
		& > *:first-child {
			border-right: 1px solid rgba(255, 255, 255, 0.2);
			padding: 20px 22px;
		}
	}
}

.TableBodyAuditor {
	display: grid;
	gap: 12px;
}

.avatar {
	width: 15px;
	height: 15px;
}

.controlLoader {
	margin: 0px 10px;
}

.BulkInputs {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	input[type="radio"] {
		margin: 0px;
	}
}

.listUsersContainer {
	display: flex;
	flex-direction: column;
	gap: 5px;
	height: 150px;
	width: 100%;
	overflow: auto;
	@include customScroll;
}

.formContainer {
	padding: 30px;
	width: 100%;
}

.formContainer h2 {
	text-align: center;
	margin-bottom: 20px;
	color: #333;
}

.formGroup {
	margin-bottom: 15px;
}

.formGroup label {
	display: block;
	font-weight: bold;
	margin-bottom: 5px;
	color: #555;
}

.formGroup input[type="text"],
.formGroup textarea,
.formGroup select {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 16px;
	color: #333;
	background-color: #f9f9f9;
}

.formGroup textarea {
	resize: vertical;
}

.formGroup select {
	cursor: pointer;
}

.formGroup button {
	width: 100%;
	padding: 10px;
	background-color: #4caf50;
	color: white;
	border: none;
	border-radius: 4px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.formGroup button:hover {
	background-color: #45a049;
}

.formGroup button:disabled {
	background-color: lightgrey;
	cursor: not-allowed;
}

.copyBtn {
	width: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
