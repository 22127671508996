@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.select {
	font-size: 14px;
	position: relative;
	z-index: 2;
	display: inline-block;
}

.headerSelect {
	color: $white !important;
	font-weight: 500;
}

.selectContent {
	width: 100%;

	.selectActive {
		width: 100%;
		background-color: $blue;
		display: flex;
		align-items: center;
		justify-content: space-between;
		span {
			margin-left: auto;
		}

		&:hover {
			background-color: rgba($blue, 0.5);
		}

		@media (max-width: 650px) {
			background-color: unset;
			border-radius: 0;
			padding: 15px 20px;
		}
	}

	@media (max-width: 650px) {
		margin-left: -15px;
		width: calc(100% + 30px);
	}
}

.selectOpened {
	z-index: 3;
}

.selectText {
	margin-right: 20px;
	user-select: none;
}

.selectActive {
	display: inline-flex;
	align-items: center;
	padding: 10px 15px;
	background: #DB493C 0% 0% no-repeat padding-box;
	border-radius: 7px;
	box-shadow: $shadow;
	cursor: pointer;
	transition: all 0.15s;
	width: max-content;

	svg {
		width: 10px;
		height: 10px;
		fill: $white;
		transform: rotate(90deg);
		margin-left: 10px;
	}
}
.selectActiveee {
	display: inline-flex;
	align-items: center;
	padding: 10px 15px;
	background: transparent 0% 0% no-repeat padding-box;
	border-radius: 0px;
	cursor: pointer;
	transition: all 0.15s;
	width: max-content;
	padding: 0px 5px;
	/* UI Properties */
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	svg {
		width: 10px;
		height: 10px;
		fill: $black;
		transform: rotate(90deg);
		margin-left: 10px;
	}
}

.whiteText {
	color: #fff;
	font-size: 12px;
	font-weight: bold;
}
.selectActiveOpened {
	border-radius: 7px 7px 0 0;
	color: $black;
}
.selectActiveOpenedWhite {
	border-radius: 7px 7px 0 0;
	color: #fff;
}

.selectActiveFull {
	width: 100%;
	justify-content: space-between;
	color: #E0E0E0;
	background: transparent;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	opacity: 1;
}

.selectDrop {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background-color: #1E1E1E;
	border-radius: 0 0 7px 7px;
	overflow: auto;
	max-height: 140px;
	z-index: 3;
	@include customScroll;
}
.selectDropUser {
	width: 420px;
	right: 0 !important;
	left: auto !important;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 60px 120px #26334d0d;
	border-radius: 10px;
	opacity: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden !important;
	max-height: none !important;
	padding-bottom: 25px;
}
.selectItem {
	padding: 8px 5px;
	transition: all 0.15s;
	cursor: pointer;
	text-align: center;
	display: flex;
	align-items: center;

	& + & {
		border-top: 1px solid rgba($secondary, 0.5);
	}
}

.selectItemActive {
	color: #DB493C;
	font-weight: 600;
	justify-content: space-between;
	svg {
		width: 15px;
		height: 15px;
		fill: #DB493C;
		margin-left: 10px;
	}
}

.selectItemRight {
	justify-content: flex-end;
	padding: 10px;
}

.btnSwith {
	height: 34px !important;
}

.divColor {
	width: 16px;
	height: 16px;
}

.whiteHeader {
	background-color: #1E1E1E;
	color: #000 !important;
	box-shadow: none !important;
	text-align: left;
	// padding: 10px 5px !important;
	font-size: 12px !important;
	svg {
		fill: #000;
	}

	&:hover {
		background-color: #1E1E1E;
	}

	span {
		font-size: 14px;
		letter-spacing: 0.88px;
		color: #1e2022;
		opacity: 1;
	}
}

.radiusBorder {
	border-radius: 20px !important;
	overflow: hidden !important;
	max-height: max-content !important;
	border: 1px solid #f1f1f5;
	background-color: white !important;
}

.blueHeader {
	box-shadow: none;
	color: $blue !important;
	background-color: $secondary;
	padding-bottom: 2px !important;
	&:hover {
		background-color: $secondary;
	}
	svg {
		fill: $blue !important;
	}
}

.whiteDrop {
	background-color: #1e1e1e !important;
}

.riskColor {
	width: 24px;
	height: 24px;
	border-radius: 50%;
}

.selectIconCl {
	display: flex;
	align-items: center;
}

.assignUser {
	background: #DB493C 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 30px #26334d0d;
	border-radius: 30px;
	opacity: 1;
	height: 50px;
	padding: 0px 30px;
	color: #fff;
	font-size: 13px;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 15px 60px;
	svg {
		fill: #c3cad9;
		height: 30px;
		width: 30px;
		padding: 9px;
		border-radius: 50px;
		transform: rotate(90deg);
		background-color: #1E1E1E;
	}
}

.addAdssignUser {
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 12px;
	font-weight: bold;
	color: #DB493C;
	margin: 10px 60px;
	cursor: pointer;
}
.svgAdd {
	background-color: #DB493C;
	width: 35px;
	height: 35px;
	padding: 8px;
	border-radius: 50%;
	fill: #fff;
}

.hederSelect {
	position: relative;
	display: flex;
	padding: 20px 20px;
	align-items: center;
	svg {
		width: 36px;
		height: 36px;
		fill: #6b7a99;
		transform: rotate(180deg);
		cursor: pointer;
		background-color: #1E1E1E;
		border-radius: 50%;
		padding: 8px;
		margin-left: 38px;
	}
}

.addAdssignUserIcon {
	width: 15px;
	height: 15px;
	fill: #DB493C;
	transform: rotate(0deg) !important;
}

.addAdssignListUser {
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 12px;
	font-weight: bold;
	color: #DB493C;
	cursor: pointer;
	display: flex;
	gap: 10px;
	align-items: center;
}

.ownerStyles {
	svg {
		width: 25px;
		height: 25px;
		fill: #c3cad9;
		background-color: #f7f8fa;
		transform: rotate(90deg);
		padding: 5px;
		border-radius: 50%;
	}
	margin: 10px 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.saveBtn {
	background-color: #DB493C !important;
	font-size: 12px !important;
	font-weight: bold !important;
	color: #fff !important;
	margin: 15px 15px !important;
	width: 80% !important;
	border-radius: 30px !important;
	margin-bottom: 0px !important;
}

.saveWithLoader {
	display: flex !important;
	align-items: center;
	justify-content: center;
	gap: 20px;
	height: 40px !important;
}
.stakeholdersStyles {
	svg {
		width: 25px;
		height: 25px;
		fill: #c3cad9;
		background-color: #f7f8fa;
		transform: rotate(90deg);
		padding: 5px;
		border-radius: 50%;
	}
	margin: 10px 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.containerSave {
	display: flex;
	justify-content: center;
}

.selectDropCalendar {
	top: 52px;
}
.calendarIcon {
	fill: #ccc !important;
	width: 10px !important;
	height: 15px !important;
}

.containerColorSelect {
	width: 65px !important;
	height: 40px !important;
	justify-content: space-around;
	display: flex;
	justify-content: center;
	align-items: center;
	& > div {
		width: 18px;
		height: 18px;
		border-radius: 50%;
	}
}

.listUsersTilte {
	position: absolute;
	left: 30%;
	font-size: 14px;
	font-weight: bold;
	color: #6b7a99;
}

.listUserName {
	font-size: 12px;
	font-weight: bold;
	color: #6b7a99;
}

.listUserNameRole {
	font-size: 12px;
	font-weight: bold;
	color: #BDBDBD;
}

.spanWithCalendar {
	width: 120px;
}
.selectWithColors {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.containerListUser {
	max-height: 500px;
	overflow-y: auto;
	overflow-x: hidden;
	@include customScroll;
	div {
		padding: 1px 0px;
	}
}
.containerListUserControls {
	max-height: 335px;
	overflow-y: auto;
	overflow-x: hidden;
	@include customScroll;
	div {
		padding: 1px 0px;
	}
}
.ListTitle {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: bold;
	color: #BDBDBD;
	margin: 10px 60px;
}

input[type="checkbox"] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 2px solid #ccc;
	outline: none;
	transition: all 0.3s ease;
	margin-right: 10px;
	box-shadow: inset 0 0 0 3px #fff;
}

input[type="checkbox"]:checked {
	background-color: #DB493C;
	border-color: #DB493C;
	box-shadow: inset 0 0 0 3px #fff !important;
}

input[type="checkbox"]:focus {
	box-shadow: 0 0 3px #DB493C;
}

.activeFunction {
	color: #DB493C;
}
