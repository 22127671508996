@import "vars";

@mixin centerElement {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

@mixin centerFlex {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin bg($size: contain) {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: $size;
}

@mixin authForm {
	position: absolute;
	top: 50%;
	left: 100%;
	transform: translate(-50%, -50%);
	max-width: 350px;
	width: 100%;

	@media (max-width: 1024px) {
		position: static;
		transform: unset;
	}
}

@mixin btn {
	background-color: $btnBG;
	color: $secondary;
	padding: 15px 20px;
	border-radius: 10px;
	transition: all 0.15s;
	width: 100%;
	font-size: 16px;
	font-weight: 500;
	user-select: none;
	cursor: pointer;

	// &:hover {
	//     filter: brightness(.8);
	// }
}

@mixin customScroll {
	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background: $secondary;
	}

	&::-webkit-scrollbar-thumb {
		background: $blue;
	}
}

@mixin circleImg {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	flex-shrink: 0;
}

@mixin listItem {
	display: flex;
	align-items: center;
	font-size: 14px;
	padding: 10px 15px;
	border-radius: 7px;
	background-color: $divisionBG;

	& + & {
		margin-top: 15px;
	}
}

@mixin defaultBtn($bgColor: $secondary) {
	padding: 5px 15px;
	border-radius: 7px;
	font-size: 14px;
	transition: all 0.15s;
	color: $secondary;
	background-color: $bgColor;

	&:hover {
		background-color: $secondary;
		color: $secondary;
	}
}

@mixin arrowBtn {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	transition: all 0.15s;
	user-select: none;

	svg {
		margin-right: 10px;
		width: 12px;
		height: 12px;
		transform: rotate(180deg);
		fill: $secondary;
		transition: all 0.15s;
	}

	&:hover {
		color: $secondary;

		svg {
			fill: $secondary;
		}
	}

	@media (max-width: 768px) {
		font-size: 12px;

		svg {
			width: 10px;
			height: 10px;
		}
	}
}

@mixin closeBtn {
	position: absolute;
	border-radius: 50%;
	width: 26px;
	height: 26px;
	// background-color: $btnBG;
	transition: all 0.15s;

	span {
		@include centerElement;
		height: 2px;
		width: 15px;
		background-color: #c3cad9;
		border-radius: 2px;
		display: block;
		transition: all 0.15s;
		&:first-child {
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&:last-child {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}

	&:hover {
		background-color: $secondary;

		span {
			background-color: #DB493C;
		}
	}
}
