@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.popup {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	@include centerFlex;
}

.popupOuter {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(128 127 127 / 16%);
	z-index: -1;
}

.popupInner {
	border-radius: 7px;
	background-color: #1E1E1E;
	max-width: 450px;
	width: 100%;

	@media (max-width: 500px) {
		max-width: calc(100% - 30px);
	}
}

.popupInnerSmall {
	max-width: 330px;
}

.popupInnerBig {
	max-width: 80%;
	height: 80%;
	border-radius: 20px;
}

.bigger {
	max-width: 60%;
	height: 90%;
	border-radius: 20px;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 30px #26334d08;
}
.extraBig {
	max-width: 75%;
	height: 90%;
	border-radius: 20px;
	background: #1A1A1A 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 30px #26334d08;
}

.popupTop {
	position: relative;
	padding: 20px 70px;
	text-align: center;
	border-bottom: 1px solid #ccc;
}

.popupClose {
	@include closeBtn;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
}

.popupTitle {
	font-size: 20px;
	text-align: center;
	font-weight: 400;
}

.addPopup {
	background-color: #1E1E1E;
}

.clandarPopup {
	min-width: 440px;
	width: 440px;
}

.popupOuterWhite {
	background-color: transparent !important;
}
