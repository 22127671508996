.profileImage {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background: #004d3c;
	color: #fff;
	font-size: 13px;
	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: 0.5px;
	min-width: 35px;
}
