@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.actions {
	margin-right: -10px;
	position: relative;

	@media (max-width: 768px) {
		position: static;
		margin-bottom: -10px;
		margin-right: -50px;
		margin-left: -15px;
		width: calc(100% + 65px);
	}
}

.actionsBtn {
	width: 40px;
	height: 30px;
	@include centerFlex;
	cursor: pointer;

	svg {
		width: 16px;
		height: 16px;
		fill: #000;
		transition: all 0.15s;
	}

	&:hover {
		svg {
			fill: #000;
		}
	}

	@media (max-width: 768px) {
		position: absolute;
		top: 8px;
		right: 5px;

		svg {
			width: 13px;
			height: 13px;
			transform: rotate(90deg);
		}
	}
}

.actionsContent {
	padding: 0 10px;
	border-radius: 20px;
	background-color: #1E1E1E;
	box-shadow: $shadow;
	position: absolute;
	top: 100%;
	right: -5px;
	z-index: 3;
	border: 1px solid #eaeaea;

	@media (max-width: 768px) {
		min-width: unset;
		width: 100%;
		position: static;
		border-radius: 0 0 7px 7px;
		margin-top: 10px;
	}
}

.actionsContentBtn {
	display: flex;
	justify-content: space-between;
	padding: 5px 5px;
	font-size: 12px;
	font-weight: 400;
	color: #000;
	cursor: pointer;
	transition: all 0.15s;
	align-items: center;
	font-weight: bold;

	svg {
		width: 15px;
		height: 15px;
		margin-left: 5px;
		flex-shrink: 0;
		fill: #000;
		transition: all 0.15s;
		transform: rotate(90deg);
	}
}

.actionsContentBtnDelete {
	color: $secondary;

	svg {
		fill: $secondary;
	}

	&:hover {
		color: $redDark;

		svg {
			fill: $redDark;
		}
	}
}

.headerAction {
	display: flex;
	padding-top: 10px;
	cursor: pointer;
	svg {
		fill: $blue;
	}
}
.addIcon {
	height: 10px;
	width: 10px;
	margin: auto;
	margin-left: 5px;
	margin-right: 8px;
	fill: $blue;
}
.addUser {
	color: $blue;
	font-size: 14px;
	margin: auto;
	margin-right: 0px;
}

.actionUser {
	width: max-content !important;
	min-width: auto !important;
	padding: 4px;
}

.addAdssignListUser {
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 12px;
	font-weight: bold;
	color: #DB493C;
	cursor: pointer;
	display: flex;
	gap: 10px;
	justify-content: left;
}

.ownerStyles {
	svg {
		width: 25px;
		height: 25px;
		fill: #c3cad9;
		background-color: #f7f8fa;
		transform: rotate(90deg);
		padding: 5px;
		border-radius: 50%;
	}
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.listUsersTilte {
	position: absolute;
	left: 30%;
	font-size: 14px;
	font-weight: bold;
	color: #6b7a99;
}

.listUserName {
	font-size: 12px;
	font-weight: bold;
	color: #6b7a99;
}

.listUserNameRole {
	font-size: 12px;
	font-weight: bold;
	color: #BDBDBD;
}

.listeUser {
	position: absolute;
	left: 52.5%;
	top: 57.5%;
}

.listReport {
	left: 0px;
	right: 0px;
	top: 37px;
	width: 100%;
}

.actionContentReport {
	border-radius: 5px;
	right: 0px;
	width: 100%;
}
