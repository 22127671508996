@import "../../../assets/styles/includes/vars";
@import "../../../assets/styles/includes/mixins";

.container {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	min-width: 1000px;
	max-height: 100vh;
}

.main {
	width: 100%;
}

.content {
	background-color: $secondary;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.navbar {
	height: 14%;
}

.sideUserShow {
	width: auto;
}
.sideUserHide {
	width: 0px;
}

.pages {
	width: 100%;
	display: flex;
	height: 86%;
	.body {
		width: 100%;
		overflow: auto;
		@include customScroll;
		padding: 30px;
		min-height: 560px;
	}
}

.noMargin {
	margin-bottom: 0px !important;
}
