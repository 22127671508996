@import "../../../assets/styles/includes/vars";
@import "../../../assets/styles/includes/mixins";

.main {
	display: flex;
	height: 100%;
	.container {
		flex-grow: 1;
	}
}

.container {
	margin: 30px;
	width: 100%;
}

.gridRow {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(228px, 228px));
	grid-gap: 20px;
	justify-content: center;
	align-items: center;
	// overflow: auto;
	margin-top: 35px;
	@include customScroll;
}

.gridRow .gridItem:hover {
	// filter: contrast(0.9);
	filter: contrast(0.7);
}

.gridRow .gridItem {
	border: 1px solid rgba(255, 255, 255, 0.1);
	transition: filter 0.5s ease;
	display: flex;
	cursor: pointer;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	text-align: center;
	flex-direction: column;
	align-items: stretch;
	max-width: 245px;
	min-height: 200px;
	border-radius: 5px;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	position: relative;
}

.gridItem {
	padding: 25px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.resourcesIcon {
	width: 35px;
	height: 35px;
	fill: #E0E0E0;
	padding: 10px;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	// border-radius: 50%;
}

.resourcesHeaderIcon {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: right;
	position: relative;
}

.bodyTypeContainer {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.extention {
	display: flex;
	width: 50px;
	height: 60px;
	border: 2px solid #DB493C;
	border-radius: 5px;
	opacity: 1;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: bold;
	color: #DB493C;
}

.centerFrameworkName {
	display: flow;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.frameworkCategory {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	font-weight: bold;
	color: #BDBDBD;
}

.centerLoader {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.resourceHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	padding: 5px;
}

.resourceHeaderLeft {
	display: flex;
	align-items: center;
	gap: 30px;
	text-align: center;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 14px;
	font-weight: bold;
}

.resourceHeaderRight {
	display: flex;
	align-items: center;
	gap: 20px;
	min-width: 300px;
}

.searchInput {
	flex: 1;
}

.addButtonWrapper {
	position: relative;
}

.addButton {
	width: 32px !important;
	height: 32px !important;
	min-width: unset !important;
	padding: 0 !important;
	border-radius: 4px !important;
	background: #DB493C !important;
	border: none;
	color: white;
	font-size: 24px !important;
	line-height: 1 !important;
	cursor: pointer;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	text-align: center !important;
	margin: 0 !important;

	span {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		height: 100% !important;
		width: 100% !important;
		margin-top: -2px !important;
	}

	&:hover {
		background: darken(#DB493C, 5%) !important;
	}
}

.dropdownMenu {
	position: absolute;
	top: calc(100% + 8px);
	right: 0;
	background: #1E1E1E;
	border-radius: 8px;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	min-width: 180px;
	z-index: 100;
	padding: 8px 0;
	border: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdownItem {
	padding: 12px 24px;
	color: #E0E0E0;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	transition: all 0.2s ease;
	text-align: left;
	background: transparent;

	&:hover {
		background: rgba(51, 97, 255, 0.05);
		color: #DB493C;
	}

	&:not(:last-child) {
		border-bottom: none;
	}
}

.btnResource {
	cursor: pointer;
	&:hover {
		color: #DB493C;
		opacity: 0.9;
	}
}

.active {
	color: #DB493C;
}

.noResource {
	font-size: 14px;
	font-weight: bold;
	color: #E0E0E0;
}

.btnResoources {
	background-color: #DB493C;
	border-radius: 35px;
	width: 150px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	float: right;
}

.actionsContent {
	width: 150px;
	padding: 0 10px;
	border-radius: 20px;
	background-color: #1E1E1E;
	box-shadow: $shadow;
	position: absolute;
	top: 100%;
	z-index: 3;
	border: 1px solid #eaeaea;

	@media (max-width: 768px) {
		min-width: unset;
		width: 100%;
		position: static;
		border-radius: 0 0 7px 7px;
		margin-top: 10px;
	}
}

.actionsContentBtn {
	display: flex;
	justify-content: center;
	padding: 15px 5px;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	cursor: pointer;
	transition: all 0.15s;
	align-items: center;
}

.actions {
	float: right;
	width: 150px;
	position: relative;

	@media (max-width: 768px) {
		position: static;
	}
}

.addBtnResource {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.textHeaderLeftIcon {
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
	width: 40px;
	height: 40px;
	padding: 10px;
	fill: #c3cad9;
	cursor: pointer;
	rotate: 180deg;
}

.btnResource {
	display: flex;
	align-items: center;
	gap: 25px;
}

.teamsPopupBtn {
	width: 112px;
	height: 40px;
	font-weight: bold;
	border-radius: 30px !important;
	background: #1E1E1E 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 30px;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-items: center;
	gap: 10px;
	@include defaultBtn;
	&:hover {
		background-color: #1e1e1e !important;
	}
	svg {
		fill: #c3cad9 !important;
		opacity: 1;
		width: 15px;
		height: 15px;
	}
}

.textHeaderReigthText {
	text-align: left;
	letter-spacing: 0px;
	color: #fff;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.btnBlue {
	width: 112px;
	height: 40px;
	font-weight: bold;
	border-radius: 30px !important;
	color: #ffffff !important;
	background: #DB493C 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 30px;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-items: center;
	gap: 10px;
	@include defaultBtn;
	&:hover {
		background-color: #DB493C !important;
	}
	svg {
		fill: #ffffff80 !important;
		opacity: 1;
		width: 15px;
		height: 15px;
	}

	.textHeadersave {
		color: #fff;
		font-size: 12px;
		font-weight: bold;
	}
}

.textHeaderRiegth {
	display: flex;
	gap: 20px;
}

.reportFormBodyLeft {
	width: 100%;
	border-right: 1px solid rgba(255, 255, 255, 0.2);
	height: 100%;
	padding-top: 20px;
}

.reportFormBodyRiegth {
	width: 30%;
	height: 100%;
	padding: 30px;
}

.reportFormBody {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}

.titleReport {
	display: flex;
	width: 100%;
	height: 90px;
	border: 1px solid rgba(255, 255, 255, 0.2);
	align-items: center;
	padding: 20px;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	font-weight: bold;
	color: #E0E0E0;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 10px 10px 10px 10px;
}

.inputStyle {
	width: 100%;
	margin: 0px !important;
}

.titleReportLeft {
	width: 20%;
	min-width: 135px;
	height: 100%;
	border-right: 1px solid rgba(255, 255, 255, 0.2);
	font-size: 16px;
	font-weight: bold;
	color: #E0E0E0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.titleReportReight {
	width: 80%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	&,
	input {
		text-align: left;
		letter-spacing: 0px;
		color: #E0E0E0;
		opacity: 1;
		font-size: 14px;
		font-weight: bold;
	}
}

.controlCardHeaderIconPopup {
	height: 25px;
	width: 25px;
}
.controlCardHeaderLeftPopup {
	display: flex;
	align-items: center;
	gap: 20px;
}

.controlCardHeaderPopup {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* UI Properties */
	background: #1E1E1E 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
	opacity: 1;
	padding: 10px 20px;
	height: 65px;
	width: 100%;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	border: 1px solid rgba(255, 255, 255, 0.2);
}

.controlCardPopup {
	width: 100%;
	border: 1px solid rgba(255, 255, 255, 0.2);
	background-color: #1E1E1E;
	margin: 0 auto;
	margin-top: 20px;
	border-radius: 10px;
	height: 100%;
}
.controlCardBodyPopup {
	background-color: #1E1E1E;
}

.arrowInverst {
	width: 30px;
	height: 30px;
	padding: 9px;
	fill: #c3cad9;
	background-color: #1E1E1E;
	border-radius: 4px;
	cursor: pointer;
	border: 1px solid rgba(255, 255, 255, 0.2);
	transform: rotate(90deg);
}

.iframePdfLoader {
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 8px;
	margin: 25px 0px;
	height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dotsStyles {
	border-radius: 50%;
}

.dropResourceBtn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:hover {
		color: #DB493C;
		.resourcesIcon {
			fill: #DB493C;
		}
	}
}

.dropResourcActionsContent {
	border-radius: 8px;
}

.deleteLoad {
	padding-bottom: 10px;
	padding-right: 8px;
	padding-top: 5px;
}

.divRepository {
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 8px;
}

.repositoryInput {
	border: 1px solid #c3cad9;
	border-radius: 4px;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	padding: 8px;
	width: 100%;
	height: 35px;
	&::placeholder {
		color: #E0E0E0;
		font-size: 12px;
		font-weight: bold;
	}
}

.repositoryBtn {
	width: 150px;
	height: 30px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #DB493C;
	color: #edeff2;
	border-radius: 4px;
	font-size: 12px;
	font-weight: bold;
}

.folderStyles {
	padding: 8px;
	width: 70px;
	height: 70px;
}

.repositoryPathDiv {
	display: flex;
	align-items: center;
}

.repositoryPath {
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	cursor: pointer;
	&:hover {
		color: #DB493C;
	}
}

.listOfRepositories {
	overflow: auto;
	height: 150px;
	@include customScroll;
	padding-bottom: 10px;
}

.repositoryInput {
	width: 100%;
}

.radioRepository {
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	display: flex;
	justify-content: left;
	align-items: center;
	gap: 8px;
	width: 100%;
	height: 40px;
	border-bottom: 1px solid #c3cad9;
	cursor: pointer;
	&:hover {
		background-color: #f5f6f7;
	}
}

.repositoriesContainer {
	padding: 16px;
}

.repositoryInputRadio {
	border: 1px solid #c3cad9;
	border-radius: 4px;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
	padding: 8px;
	height: 15px;
	width: 15px;
	&::placeholder {
		color: #E0E0E0;
		font-size: 12px;
		font-weight: bold;
	}
}

.repoBtns {
	display: flex;
	align-items: center;
	justify-content: right;
	gap: 8px;
}

.repoDeleteBtn {
	background-color: red;
	color: #ffffff !important;
}

.repoMessage {
	font-size: 13px;
	font-weight: bold;
	color: #E0E0E0;
	text-align: justify;
}

.spanRepoMessage {
	color: #E0E0E0;
}

.NoRepoMessage {
	color: #E0E0E0;
	font-size: 12px;
	font-weight: bold;
	width: 100%;
	text-align: center;
}

.desableClick {
	pointer-events: none;
	color: #c3cad9;
}

.sortDiv {
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
}

.sortInput {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	input {
		margin: 0px;
	}
}

.resourcesMain {
	width: 100%;
	height: 100%;
}

.createAndSort {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
	margin: 15px 0px;
}

.noPadding {
	padding: 0px;
}

.cardContent {
	height: 100%;
}

.upload input[type="file"] {
	appearance: initial;
	opacity: 0;
	cursor: pointer !important;
	position: absolute;
	width: 100%;
	padding: 9px;
}

.upload .choosen span {
	display: block;
}

.upload {
	width: 100%;
	height: 100%;
}

.formUp p {
	text-align: center;
	margin: 5px;
}

.customUploadBtn {
	width: 100%;
	height: 100%;
	background-color: #DB493C;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	display: flex;
	align-items: center;
	border-radius: 5px;
	justify-content: center;
	text-align: center;
	padding: 9px;
	cursor: pointer;
}

.documentDetailsContainer {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.headerRight {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.actions {
	position: relative;
}

.btnResoources {
	width: 32px;
	height: 32px;
	border-radius: 4px;
	background: var(--primary-color);
	border: none;
	color: white;
	font-size: 20px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background: var(--primary-color-dark);
	}
}

.actionsContent {
	position: absolute;
	top: 100%;
	right: 0;
	margin-top: 0.5rem;
	background: #1E1E1E;
	border-radius: 4px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	z-index: 10;
	min-width: 180px;
}

.actionsContentBtn {
	padding: 0.75rem 1rem;
	cursor: pointer;
	transition: background 0.2s;

	&:hover {
		background: var(--hover-color);
	}

	&:first-child {
		border-radius: 4px 4px 0 0;
	}

	&:last-child {
		border-radius: 0 0 4px 4px;
	}
}
