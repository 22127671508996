@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.gpt3Form {
	width: 100%;
	padding: 20px;
}

.inputStyle {
	width: 100%;
	height: 100px;
	font-size: 14px;
	font-weight: bold;
	padding: 10px;
	box-sizing: border-box;
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 5px;
}

.buttonStyle {
	background-color: green;
	color: white;
	font-size: 12px;
	font-weight: bold;
	padding: 10px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 33px;
}

.listBtnSubmit {
	display: flex;
	gap: 20px;
	align-items: center;
	margin-top: 5px;
}

.chat {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 10px;
	opacity: 1;
	position: relative;
}

.chatHistory {
	display: flex;
	flex-direction: column;
	@include customScroll;
	padding: 15px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 100px;
	overflow: auto;
}

.chatMessage {
	display: inline-block;
	border-radius: 8px;
	max-width: 100;
	word-wrap: break-word;
}

.botMessage {
	background-color: #e8f5e9;
	align-self: flex-start;
}

.chatInput {
	flex-grow: 1;
	border: none;
	padding: 8px;
	font-size: 14px;
	resize: none;
	height: 35px;
}

.chatButton {
	border: none;
	color: #fff;
	padding: 8px 16px;
	font-size: 16px;
	cursor: pointer;
}

.formInput {
	display: flex;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	border-radius: 10px;
	opacity: 1;
	padding: 15px;
	align-items: center;
	font-size: 12px;
	font-weight: bold;
	position: absolute;
	bottom: 15px;
	width: 95%;
	left: 2.5%;
}

.inputSend {
	width: 30px;
	height: 30px;
	fill: #DB493C;
}

.userQuestion {
	align-self: flex-end;
	background-color: #e3f2fd;
}

.messageUser {
	display: flex;
	padding: 20px;
	gap: 10%;
	justify-content: space-between;
}

.userIcon {
	width: 50px !important;
	height: 50px !important;
}

.textName {
	display: flex;
	flex-direction: column;
	gap: 14px;
	width: 80%;
}
.text {
	text-align: left;
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	white-space: break-spaces;
	font-family: inherit;
}

.userName {
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.userIconDive {
	display: flex;
	justify-content: center;
	width: 10%;
}

.dotsLoader {
	display: flex;
	justify-content: left;
	align-items: center;
	width: 80%;
}

.dot {
	width: 10px;
	height: 10px;
	margin: 2px;
	border-radius: 50%;
	background-color: #6b7a99;
	animation: load 1s infinite;
}

.dot2 {
	animation-delay: 0.2s;
}

.dot3 {
	animation-delay: 0.4s;
}

@keyframes load {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.relativDiv {
	position: relative;
}

.toResourceBtn {
	position: absolute !important;
	width: max-content !important;
	height: max-content !important;
	font-size: 10px !important;
	font-weight: bold !important;
	background-color: #DB493C !important;
	color: #fff !important;
	padding: 5px !important;
	border-radius: 4px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	top: -10px;
	right: 0px;
}

.chatLogo {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-size: 12px;
	font-weight: bold;
}
