@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";
@import "/src/assets/styles/includes/fonts";

// Dark theme variables
$dark-background: #1a1a1a;
$dark-secondary: #2d2d2d;
$dark-text: #ffffff;
$dark-text-secondary: rgba(255, 255, 255, 0.7);
$dark-border: #404040;
$dark-input-bg: #3d3d3d;
$dark-hover: rgba(255, 255, 255, 0.1);

*,
*::before,
*::after {
	box-sizing: border-box;
}

body,
html {
	margin: 0;
}

body {
	font-family: "Gotham Book", sans-serif;
	color: $black;
	font-weight: 500;
	line-height: 1.3;
	background-color: $secondary;
	@include customScroll;
	background-color: $dark-background;
	color: $dark-text;
}

a {
	text-decoration: none;
	color: inherit;
	color: #DB493C;
  
	&:hover {
		color: #B13931;
	}
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	color: $dark-text;
}

p, span, div {
	color: $dark-text-secondary;
}

button,
input,
textarea {
	background-color: unset;
	border: none;
	font-family: inherit;
	outline: none;
	font-size: inherit;
	color: inherit;
}

button {
	cursor: pointer;
	padding: 0;
	user-select: none;
}

.globalLoader {
	width: 100%;
	height: 100vh;
	@include centerFlex;
}

.empty-block {
	padding: 10px;
	border-radius: 10px;
	text-align: center;
}

.dwPlXY:not(:last-of-type) {
	border-bottom-style: hidden !important;
}

.beUiVL {
	color: #999 !important;
	padding: 10px 0px !important;
	font-size: 12.5px !important;
}

.bQdYbR:not(:first-of-type) {
	color: #999 !important;
}

.dwPlXY > .bQdYbR:first-of-type {
	color: #333 !important;
	font-size: 12px !important;
	opacity: 1 !important;
}

.rdw-link-modal-target-option {
	margin-bottom: 5px !important;
	font-size: smaller;
}

.rbc-month-header {
	background-color: #0077ff12 !important;
	height: 70px !important;
	color: #999 !important;
	font-size: 12px;
}

.rbc-btn-group {
	color: #DB493C !important;
	background-color: #ff000000 !important;
}
.rbc-toolbar {
	justify-content: space-between;
	font-size: 10px;
	margin: 10px 10px 10px 10px;
}
.rbc-toolbar button {
	color: #E0E0E0 !important;
	font-weight: bold;
}
.rbc-calendar {
	color: #E0E0E0;
}
.rbc-header {
	margin-top: auto !important;
	margin-bottom: auto !important;
	border-bottom: 0px !important;
	color: #E0E0E0;
	background-color: $dark-background;
	padding: 27px 3px;
}
.rbc-button-link {
	font-weight: bold;
	font-size: 10px;
	color: #E0E0E0;
}

.rbc-header + .rbc-header {
	color: #E0E0E0;
	background-color: $dark-background;
}

.react-datepicker-popper {
	z-index: 3;
}

.ql-editor {
	min-height: calc(1.2em * 10);
	// max-height: calc(1.2em * 10);
	// color: #E0E0E0;;
}

footer {
	background-color: #222;
	padding: 1em;
	width: 100%;
	position: absolute;
	bottom: 0;
	width: 100%;
}
footer h5 {
	color: #fff;
}
footer nav ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
}

footer nav ul li {
	margin: 0 1em;
}

footer nav ul li a {
	color: #fff;
	text-decoration: none;
}

footer nav ul li a:hover {
	text-decoration: underline;
}

.fc .fc-toolbar.fc-header-toolbar {
	border: 1px solid #dadee6;
	border-bottom: 0px;
	border-radius: 10px 10px 0px 0px;
	margin: 0px;
	padding: 10px;
}

.fc .fc-button {
	background-color: transparent;
	color: #E0E0E0 !important;
	font-weight: bold;
	opacity: 1 !important;
	border: 0px !important;
	font-size: 12px;
	font-weight: bold;
}

.fc .fc-button-primary:hover {
	background-color: #e6e6e6 !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
	background-color: #e6e6e6 !important;
	border-radius: 4px;
}

.fc .fc-button-primary:disabled {
	background-color: #e6e6e6 !important;
	border-radius: 4px;
}

.fc .fc-toolbar-title {
	font-size: 12px;
	font-weight: bold;
	color: #E0E0E0;
}

.fc-col-header {
	height: 70px !important;
	font-size: 12px !important;
	font-weight: bold !important;
	color: #E0E0E0 !important;
}

th > .fc-scrollgrid-sync-inner {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #E0E0E0;
}

.fc-h-event .fc-event-main {
	color: rgb(77, 94, 128);
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start,
.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end),
.fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-start) {
	border: 0px;
	background-color: transparent !important;
}

.fc .fc-daygrid-day-number {
	font-size: 12px;
	font-weight: bold;
	color: rgb(77, 94, 128) !important;
}

.fc-scroller,
.fc-scroller-liquid-absolute {
	overflow: hidden auto;
	@include customScroll;
}

.fc,
.fc-media-screen,
.fc-direction-ltr,
.fc-theme-standard {
	padding: 10px 30px;
}

.fc .fc-daygrid-more-link {
	color: rgb(77, 94, 128) !important;
	font-size: 12px;
	font-weight: bold;
}

.fc .fc-popover-header {
	font-size: 12px;
	font-weight: bold;
	color: rgb(77, 94, 128) !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
	background-color: transparent !important;
	border: 0px !important;
}

.fc .fc-timegrid-slot-label {
	font-size: 12px;
	font-weight: bold;
	color: rgb(77, 94, 128);
	text-align-last: center;
}

.fc .fc-timegrid-axis-cushion {
	font-size: 12px;
	font-weight: bold;
	color: rgb(77, 94, 128);
	text-align-last: center;
}

// Cards & Containers
.card, .container, .box {
	background-color: $dark-secondary;
	border: 1px solid $dark-border;
	border-radius: 8px;
}

// Form elements
input, textarea, select {
	background-color: $dark-input-bg;
	border: 1px solid $dark-border;
	color: $dark-text;
  
	&::placeholder {
		color: rgba(255, 255, 255, 0.45);
	}
  
	&:focus {
		border-color: #DB493C;
		outline: none;
	}
}

// Buttons
.btn {
	padding: 8px 16px;
	border-radius: 4px;
	transition: all 0.2s;
  
	&-primary {
		background-color: #DB493C;
		color: $dark-text;
    
		&:hover {
			background-color: #0056b3;
		}
	}
  
	&-secondary {
		background-color: $dark-input-bg;
		color: $dark-text;
		border: 1px solid $dark-border;
    
		&:hover {
			background-color: $dark-hover;
		}
	}
}

// Tables
table {
	background-color: $dark-secondary;
	border: 1px solid $dark-border;
  
	th, td {
		border: 1px solid $dark-border;
		color: $dark-text;
	}
  
	thead {
		background-color: $dark-input-bg;
	}
  
	tbody tr:hover {
		background-color: $dark-hover;
	}
}

// Scrollbars
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-track {
	background: $dark-background;
}

::-webkit-scrollbar-thumb {
	background: $dark-border;
	border-radius: 4px;
  
	&:hover {
		background: lighten($dark-border, 10%);
	}
}
