@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.container {
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 10px;
	opacity: 1;
	padding: 20px;
	height: 160px;
	color: #fff;
}

// .bigCard{
//     width: 300px;
// }

// .smallCard{
//     width: 180px;
// }

.moreSvg {
	width: 20px;
	height: 20px;
	fill: #fff;
	cursor: pointer;
}

.cardHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.cardBody {
	margin-top: 25px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: -webkit-fill-available;
	height: 80px;
}

.btnViewAll {
	padding: 9px 15px 9px 15px;
	border-radius: 12px;
	filter: brightness(0.9);
	border-radius: 30px;
	opacity: 1;
	color: white;
	cursor: pointer;
	font-size: 11px;
}

.cardHeaderText {
	text-align: left;
	letter-spacing: 0.3px;
	color: #ffffff;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.cardHeaderNumber {
	font-size: 22px;
}
.equelHighte {
	position: absolute !important;
	top: 0px !important;
}
