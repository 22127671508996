@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.toolbarClassName {
	justify-content: left;
	border: 0px !important;
}

.send {
	display: flex;
	justify-content: right;
}

.sendIcon {
	height: 28px;
	width: 30px;
	fill: $blue;
	cursor: pointer;
}

.dropzone {
	text-align: center;
	border: 3px #ccc dashed;
	width: 100%;
	margin: auto;
	cursor: pointer;
}
.fileContainer {
	margin-bottom: 10px;
}

.editorStyles {
	height: 100%;
	max-height: 500px;
	width: 100%;
	overflow: auto;
	@include customScroll;
	& > div {
		border: none !important;
	}
}

.error {
	border-bottom: 4px solid red;
}
