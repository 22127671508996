@import "../../../assets/styles/includes/vars";
@import "../../../assets/styles/includes/mixins";

.sidebar {
	min-height: 100%;
	height: 100%;
	width: 4rem;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	transition: 0.3s width;
	gap: 14px;
	padding-left: 10px;
}

.boxiconContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	cursor: pointer;
	position: relative;
	color: $blackLighter;
	padding: 10px;
	align-items: center;
	&:hover {
		color: #DB493C;
		.svgIcons {
			fill: #DB493C;
		}
	}
	width: -webkit-fill-available;
}

.expandedBoxiconContainer {
	justify-content: flex-start;
}

.boxicon {
	animation-iteration-count: 1;
	z-index: 2;
}

.active {
	background-color: #1E1E1E;
	border-radius: 6px;
	box-shadow: 0px 2px 5px #26334d08;
}

.active::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 95%;
	opacity: 1;
	z-index: -1;
	top: 50%;
	transform: translateY(-50%);
	left: -0.25rem;
	border-top-right-radius: 0.8rem;
	border-bottom-right-radius: 0.8rem;
}

.boxicon::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 95%;
	// background-color: #373737;
	opacity: 0;
	z-index: -1;
	top: 50%;
	transform: translateY(-50%);
	left: -0.25rem;
	border-top-right-radius: 0.8rem;
	border-bottom-right-radius: 0.8rem;
}

.boxiconContainer:hover > .boxicon::after {
	opacity: 1;
	transition: 0.1s;
}

.sidebar > *:first-child {
	margin: 31px 0px 0px 0px;
	color: #E0E0E0;
	font-size: 12px;
	font-weight: bold;
}

.sidebar7 > *:nth-child(7) {
	//margin-top: auto;
	margin-top: 50px;
}

.sidebar6 > *:nth-child(7) {
	//margin-top: auto;
	margin-top: 50px;
}

.finalIcon {
	background-color: $redDark;
}

.description {
	opacity: 0;
	z-index: 10;
	font-weight: bold;
	margin: 0;
	transition: 0.2s opacity;
	text-align: left;
	font-size: 13px;
	letter-spacing: 0px;
	color: #E0E0E0;
	margin-top: 6px;
}

.activeDescription {
	color: #DB493C;
}

.expanded {
	min-width: 260px;
	align-items: flex-start;
}

.showDescription {
	opacity: 1;
}

.hideDescription {
	display: none;
}

.container {
	display: flex;
}

.text {
	color: white;
	z-index: 100;
	margin: 0 auto;
	align-self: center;
	font-size: 32px;
}

.svgIcons {
	height: 25px;
	width: 35px;
	transition: 0.5s width;
	fill: #E0E0E0;
}

.hideSvg {
	transition: 0.5s width;
}

.activeSvg {
	fill: #DB493C;
}

.svgIconsTop {
	height: 35px;
	width: 45px;
	cursor: pointer;
}

.logo {
	padding: 24px;
	padding-left: 18px;
	display: flex;
}

.logoText {
	margin-bottom: auto;
	margin-top: auto;
	width: 150px;
	height: 27px;
	img {
		width: 150px;
		height: 27px;
	}
}

.sideHeader {
	margin-left: 15px !important;
}

.sousListText {
	display: flex;
	font-size: 12px;
	font-weight: bold;
	margin-left: 62px !important;
	color: #E0E0E0;
	margin-top: 8px;
	&:hover {
		color: #DB493C;
	}
}

.firstChild {
	margin-bottom: 5px;
}

.thirdItem {
	width: 100%;
}

.arrowSvgOpen {
	width: 10px;
	height: 10px;
	transform: rotate(-90deg);
	fill: #E0E0E0;
}

.arrowSvgClosed {
	transform: rotate(90deg);
}

.therdItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 75%;
}

.margingLeft {
	margin-left: 14px !important;
}

.boxIconText {
	display: flex;
	align-items: center;
	gap: 10px;
}

.hideLink {
	display: none;
}
