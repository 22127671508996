@import "../../../assets/styles/includes/vars";
@import "../../../assets/styles/includes/mixins";

.list {
	width: 100%;
	background: #1E1E1E 0% 0% no-repeat padding-box;
	box-shadow: 0px 60px 120px #26334d08;
	border-radius: 10px;
	opacity: 1;
	display: flex;
	flex-direction: column;
	float: right;
	padding: 0px 5px;
	margin-bottom: 50px;
	border: 1px solid rgba(255, 255, 255, 0.1);;
}

.item {
	display: flex;
	justify-content: space-between;
	width: 100%;
	border-bottom: 2px solid #303030;
	padding: 15px 15px;
	align-items: center;
}

.userSvg {
	width: 15px;
	height: 15px;
	transform: rotate(90deg);
}

.userImgSvg {
	width: 25px;
	height: 25px;
	min-width: 35px;
	min-height: 35px;
	fill: #ccc;
	margin-right: 5px;
}

.footerList {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	padding: 15px 0px;
	cursor: pointer;
}

.userInfo {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 10px;
	cursor: pointer;
}

.headerText {
	font-weight: bold;
	font-size: 14px;
}

.userInfoName {
	font-size: 12px;
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	margin-right: 5px;
}

.userInfoRole {
	font-size: 12px;
	text-align: left;
	letter-spacing: 0px;
	color: #BDBDBD;
	opacity: 1;
}

.loaderStyles {
	margin: 0 auto;
	padding: 50px 0px;
	font-size: 14px;
	font-weight: bold;
	color: #E0E0E0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.eventsTitle {
	text-align: left;
	letter-spacing: 0px;
	color: #BDBDBD;
	opacity: 1;
	font-size: 10px;
	font-weight: bold;
}

.eventsText {
	text-align: left;
	letter-spacing: 0px;
	color: #E0E0E0;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	cursor: pointer;
}

.eventsDetails {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.homeProfile {
	min-width: 35px !important;
	min-height: 35px !important;
	max-width: 35px !important;
	max-width: 35px !important;
}

.containerListUser {
	max-height: 335px;
	overflow: auto;
	@include customScroll;
}

.loaderCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

.loaderEventsLogs {
	display: flex;
	align-items: center;
	justify-content: center;
}
